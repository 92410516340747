import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

const CheckBoxGroup = ({ options, ...props }) => {
    const [selectedItems, setSelectedItems] = useState('');

    const onHandleSelect = (value) => {
        const isPresent = selectedItems.indexOf(value);
        if (isPresent !== -1) {
            const remaining = selectedItems.filter((item) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems) => [...prevItems, value]);
        }
    };

    useEffect(() => {
        if (props?.defaultValue || props?.defaultValue == '') {
            let defaultValue = props?.defaultValue;
            setSelectedItems(props?.defaultValue != '' ? defaultValue.split(',') : '');
        }
    }, [props?.defaultValue]);

    useEffect(() => {
        props?.setValue(props?.name, selectedItems.length > 0 ? selectedItems.join(',') : selectedItems);
    }, [selectedItems, props?.setValue]);

    return (
        options?.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {options?.map((option) => {
                    return (
                        <FormControlLabel
                            control={
                                <Controller
                                    name={props?.name}
                                    control={props?.control}
                                    render={() => (
                                        <Checkbox
                                            checked={selectedItems.includes(option?.code)}
                                            onChange={() => onHandleSelect(option?.code)}
                                        />
                                    )}
                                />
                            }
                            label={option?.codeNm}
                            key={option?.code}
                        />
                    );
                })}
            </Box>
        )
    );
};

export default CheckBoxGroup;
