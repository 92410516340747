import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const CommunityApi = createApi({
    reducerPath: "CommunityApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Community"],
    endpoints: (build) => ({
        noticeList: build.query({
            query: (params) => `${URL.NOTICE_LIST}?${params}`,
            providesTags: ["LIST"]
        }),
        noticeDetail: build.query({
            query: (params) => `${URL.NOTICE_DETAIL}?${params}`,
            providesTags: ["NOTICE_DETAIL"]
        }),
        faqList: build.query({
            query: (params) => `${URL.FAQ_LIST}?${params}`,
            providesTags: ["FAQ_LIST"]
        }),
        reviewList: build.query({
            query: (params) => `${URL.REVIEW_LIST}?${params}`,
            providesTags: ["REVIEW_LIST"]
        }),
        reviewDetail: build.query({
            query: (params) => `${URL.REVIEW_DETAIL}?${params}`,
            providesTags: ["REVIEW_DETAIL"]
        }),
        createReviewBoard: build.mutation({
            query: (body) => ({
                url: `${URL.REVIEW_CREATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["REVIEW_LIST", "REVIEW_DETAIL"]
        }),
        contactCreate: build.mutation({
            query: (body) => ({
                url: `${URL.CONTACT}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["CONTACT_LIST"]
        }),
        inquiryCreate: build.mutation({
            query: (body) => ({
                url: `${URL.INQUIRY_CREATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["CONTACT_LIST"]
        }),
        contactList: build.query({
            query: (params) => `${URL.CONTACT_LIST}?${params}`,
            providesTags: ["CONTACT_LIST"]
        }),
        contactDetail: build.query({
            query: (params) => `${URL.CONTACT_DETAIL}?${params}`,
            providesTags: ["CONTACT_DETAIL"]
        }),
        qnaList: build.query({
            query: (params) => `${URL.QNA_LIST}?${params}`,
            providesTags: ["QNA_LIST"]
        }),
        dynamicPageData: build.query({
            query: (params) => `${URL.DYNAMIC_PAGE}/${params}`,
            providesTags: ["PAGE"]
        }),
        qnaDetail: build.query({
            query: (params) => `${URL.QNA_DETAIL}?${params}`,
            providesTags: ["QNA_DETAIL"]
        }),
        qnaCreate: build.mutation({
            query: (body) => ({
                url: `${URL.QNA_CREATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["QNA_LIST"]
        }),
        qnaUpdate: build.mutation({
            query: (body) => ({
                url: `${URL.QNA_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["QNA_LIST", "QNA_DETAIL"]
        }),
        deleteQna: build.mutation({
            query: (body) => ({
                url: `${URL.QNA_DELETE}/${body}`,
                method: "DELETE",
                body: body
            }),
            invalidatesTags: ["QNA_LIST", "QNA_DETAIL"]
        }),
        deleteQnas: build.mutation({
            query: (body) => ({
                url: `${URL.QNA_DELETE}`,
                method: "DELETE",
                body: body
            }),
            invalidatesTags: ["QNA_LIST"]
        }),
        galleryList: build.query({
            query: (params) => `${URL.GALLERY_LIST}?${params}`,
            providesTags: ["GALLERY_LIST"]
        }),
        galleryDetail: build.query({
            query: (params) => `${URL.GALLERY_DETAIL}?${params}`,
            providesTags: ["GALLERY_DETAIL"]
        }),
        staffList: build.query({
            query: (params) => `${URL.STAFF_LIST}?${params}`,
            providesTags: ["STAFF_LIST"]
        }),
        staffCeo: build.query({
            query: () => `${URL.STAFF_CEO}`,
            providesTags: ["STAFF_LIST"]
        }),
        staffCto: build.query({
            query: () => `${URL.STAFF_CTO}`,
            providesTags: ["STAFF_LIST"]
        }),
        staffVice: build.query({
            query: () => `${URL.STAFF_VICE}`,
            providesTags: ["STAFF_LIST"]
        }),
        staffViceTwo: build.query({
            query: () => `${URL.STAFF_VICE_TWO}`,
            providesTags: ["STAFF_LIST"]
        }),
        certList: build.query({
            query: () => `${URL.CERT_LIST}`,
            providesTags: ["CERT_LIST"]
        }),
        portfolioList: build.query({
            query: () => `${URL.PORTFOLIO_LIST}`,
            providesTags: ["PORTFOLIO_LIST"]
        }),
        youtubeList: build.query({
            query: () => `${URL.YOUTUBE_LIST}`,
            providesTags: ["YOUTUBE_LIST"]
        }),
        newsList: build.query({
            query: (params) => `${URL.NEWS_LIST}?${params}`,
            providesTags: ["LIST"]
        }),
        newsDetail: build.query({
            query: (params) => `${URL.NEWS_DETAIL}?${params}`,
            providesTags: ["NEWS_DETAIL"]
        }),
    })
});

export const {
    useNoticeListQuery,
    useNoticeDetailQuery,
    useFaqListQuery,
    useStaffListQuery,
    useStaffCtoQuery,
    useStaffCeoQuery,
    useStaffViceQuery,
    useStaffViceTwoQuery,
    useDynamicPageDataQuery,
    useContactCreateMutation,
    useInquiryCreateMutation,
    useContactListQuery,
    useContactDetailQuery,
    useQnaListQuery,
    useQnaCreateMutation,
    useDeleteQnaMutation,
    useDeleteQnasMutation,
    useQnaUpdateMutation,
    useQnaDetailQuery,
    useGalleryListQuery,
    useCertListQuery,
    usePortfolioListQuery,
    useYoutubeListQuery,
    useGalleryDetailQuery,
    useReviewListQuery,
    useReviewDetailQuery,
    useCreateReviewBoardMutation,
    useNewsListQuery,
    useNewsDetailQuery,
} = CommunityApi;
