import React from "react";
import { useRoutes } from "react-router-dom";

import MainRoutes from "./mainRoutes";

export default function ThemeRoutes() {
    const routes = useRoutes([...MainRoutes]);

    return <>{routes}</>;
}
