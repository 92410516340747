import React from "react";

function Tissue() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn tissue sub_container">
                <h2 class="sub_custom_ttl">조직도</h2>
                <div class="img">
                    <img src="https://i.postimg.cc/3xDCYzt7/tissue.png" alt="조직도" />
                </div>
                <h2 class="sub_custom_ttl">단체설립</h2>
                <table>
                    <colgroup>
                        <col width="16%" />
                        <col width="16%" />
                        <col width="16%" />
                        <col width="auto" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td class="gray">단체명</td>
                            <td>희망나무재단</td>
                            <td class="gray">법인형태</td>
                            <td>사단법인</td>
                        </tr>
                        <tr>
                            <td class="gray">법인유형</td>
                            <td>전국법인</td>
                            <td class="gray">법인기능</td>
                            <td>사회복지운영법인(○)</td>
                        </tr>
                        <tr>
                            <td class="gray">대표자</td>
                            <td>이원제</td>
                            <td class="gray">주소</td>
                            <td>서울 서초구 바우뫼로41길 8 (양재동) 3F</td>
                        </tr>
                        <tr>
                            <td class="gray">설립허가일</td>
                            <td>2020.12.00</td>
                            <td class="gray">설립등기일</td>
                            <td>2020.12.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Tissue;
