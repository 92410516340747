import React from "react";

function Business() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn business container">
                <h2>
                    <span>희망나무</span> 청년문화교류 온라인 콘텐츠 사업
                </h2>
                <p>청년문화교류사업, 문화 콘텐츠 보급, 1인가구 문화교류</p>
                <ul>
                    <li>해마다 1인가구 청년문화교류 콘텐츠 사업을 진행하였고, 지속적으로 꾸준한 지원을 약속합니다.</li>
                    <li>온, 오프라인 문화교류를 통해 다양한 청년 문화 발전을 위해 노력합니다.</li>
                    <li>
                        역사, 문화, 예술 전시, 공연, 심리, 인문 등을 학습하면서 다양한 문화교류컨텐츠를 경험하고, 아카데미 참여만으로
                        기부활동에 동참할 수 있습니다.
                    </li>
                </ul>
                <div class="business_conts">
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/43PZ8r7R/buss01.png" alt="희망나무" />
                        </div>
                        <div class="text">
                            <h3>위생용품 지원</h3>
                            <p>국내 저소득가정 여성청소년들이 건강하게 자랄 수 있도록 생리대 또는 생리대키트를 지원합니다.</p>
                            <p>
                                * 생리대키트 : 6개월 분량의 생리대, 초경 안내서, 찜질팩, 기초화장품, 에코백, 손편지 등(키트 구성품은 상황에
                                따라 변경될 수 있습니다)
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="text">
                            <h3>교육 지원</h3>
                            <p>교육 사각지대에 있는 아이들이 배움을 이어 나갈 수 있도록 학원비, 학용품, 도서 등을 지원합니다.</p>
                        </div>
                        <div class="img">
                            <img src="https://i.postimg.cc/Bvm95Hkq/buss02.png" alt="희망나무" />
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="item">
                        <img src="https://i.postimg.cc/nL93XZHH/buss02.png" alt="희망나무" />
                        <article>
                            “생리를 시작한지 5개월이 되었는데, 학생인 저에겐 항상 부담이 컸어요. <br />
                            근데 생리대와 물품들, 편지를 선물 받고 제가 정말 소중한 존재인 걸 <br />
                            다시 한 번 깨닫고 저를 사랑할 수 있는 계기가 된 것 같아요” <br />
                            <br />- 신은진(가명, 13세)
                        </article>
                    </div>
                    <div class="item">
                        <img src="https://i.postimg.cc/nL93XZHH/buss02.png" alt="희망나무" />
                        <article>
                            “식료품키트를 지원받았는데, 성장기 아이들에게 필요한 영양 가득한 <br />
                            음식들이 골고루 들어 있어서 아이들에게 큰 힘이 되었습니다. <br />
                            아이들을 위해 따뜻한 마음 나눠 주셔서 감사합니다.” <br />
                            <br />- 행복뜰안지역아동센터 김수경 선생님
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Business;
