import React from 'react';

export default function Location() {
    return (
        <div className="conts">
            <div className="cont_wrap">
                <div className="map_wrap container">
                    <div className="map">
                        <h3 className="map_ttl">한국 본사 </h3>
                        <div className="map_out">
                            <div className="map_inner">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.7419580560863!2d126.8842717117679!3d37.466814071948036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61e90abef5cf%3A0x7b4cce9ae95d70db!2s402%2C%205%20Gasan%20digital%201-ro%2C%20Geumcheon-gu%2C%20Seoul%2C%20South%20Korea!5e0!3m2!1sen!2smn!4v1697157203170!5m2!1sen!2smn"
                                    allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="address_info ">
                                <div className="map_container kr_pad">
                                    <div>
                                        <span className="add">ADD</span>
                                        <p>서울 금천구 가산디지털1로 5 대륭테크노타운20차 402호</p>
                                    </div>
                                    <div>
                                        <span className="tel">TEL</span>
                                        <p>1644-5805</p>
                                    </div>
                                    <div>
                                        <span className="fax">FAX</span>
                                        <p>02-6919-1793</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map">
                        <h3 className="map_ttl mon_ttl">몽골 지사</h3>
                        <div className="map_out">
                            <div className="map_inner">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.1464775072936!2d106.8876667122599!3d47.91420317109967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9692f8d5aef349%3A0x719fcb0e2931a298!2sGrand%20Plaza!5e0!3m2!1sen!2smn!4v1697157253175!5m2!1sen!2smn"
                                    allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="address_info">
                                <div className="map_container">
                                    <div>
                                        <span className="add">ADD</span>
                                        <p>#1104-1105, 47/1 apartment, Seoul street, 2nd khoroo, Bayangol district,
                                            Ulaanbaatar
                                            city, Mongolia</p>
                                    </div>
                                    <div>
                                        <span className="tel">TEL</span>
                                        <p>+976-7011-0023</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="go-top"></div>
        </div>
    )
}