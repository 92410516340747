import React from "react";

function Social() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn business container">
                <h2>
                    <span>희망나무</span> 청년문화교류 온라인 콘텐츠 사업
                </h2>
                <p>청년문화교류사업, 문화 콘텐츠 보급, 1인가구 문화교류</p>
                <ul>
                    <li>해마다 1인가구 청년문화교류 콘텐츠 사업을 진행하였고, 지속적으로 꾸준한 지원을 약속합니다.</li>
                    <li>온, 오프라인 문화교류를 통해 다양한 청년 문화 발전을 위해 노력합니다.</li>
                    <li>
                        역사, 문화, 예술 전시, 공연, 심리, 인문 등을 학습하면서 다양한 문화교류컨텐츠를 경험하고, 아카데미 참여만으로
                        기부활동에 동참할 수 있습니다.
                    </li>
                </ul>
                <div class="business_conts">
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/FK1NYMVP/content01.png" alt="희망나무" />
                        </div>
                        <div class="text">
                            <h3>문화 교류 지원</h3>
                            <p>
                                고립된 청년 1인가구를 위해 공연, 전시, 역사, 예술 등<br /> 다양한 온오프라인 문화교류 프로그램을
                                운영함으로써 활기찬
                                <br /> 청년 문화 발전을 만들어갑니다..
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="text">
                            <h3>교육 지원</h3>
                            <p>
                                교육 사각지대에 있는 유아동, 청소년들이 배움을 이어
                                <br /> 나갈 수 있도록 강사 초빙 특강, 그룹스터디 등 <br />
                                다양한 교육 프로그램을 진행합니다.
                            </p>
                        </div>
                        <div class="img">
                            <img src="https://i.postimg.cc/1RDPVcDZ/content02.png.png" alt="희망나무" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/L6TpN9yC/content03.png" alt="희망나무" />
                        </div>
                        <div class="text">
                            <h3>온라인 문화콘텐츠 지원</h3>
                            <p>
                                시대에 발맞춰 문화콘텐츠 프로그램을 스토리텔링, 예능,
                                <br /> 챌린지, 댄스 등 다양한 형태로 제작하여 온라인상에서
                                <br /> 누구나 쉽게 참여할 수 있도록 지원합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="sub_conts_inn social sub_container">
                <section class="social_01">
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/s2t24HMV/social-01.png" alt="사랑나눔지원" />
                        </div>
                        <div class="txt">
                            <h3>사랑나눔지원</h3>
                            <p>
                                소외된 이웃을 위해 공연, 전시 등 다양한 행사를 통해
                                <br /> 문화예술을 경험하게 하고, 물품 후원 및 봉사활동을 하고 있습니다.
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/43PZ8r7R/buss01.png" alt="위생용품 지원" />
                        </div>
                        <div class="txt">
                            <h3>위생용품 지원</h3>
                            <p>
                                국내 저소득가정 여성청소년들이 건강하게 자랄 수 있도록
                                <br /> 생리대 또는 생리대키트를 지원합니다.
                            </p>
                            <br />
                            <p>
                                * 생리대키트 : 6개월 분량의 생리대, 초경 안내서, 찜질팩, 기초화장품,
                                <br /> 에코백, 손편지 등(키트 구성품은 상황에 따라 변경될 수 있습니다)
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/Bvm95Hkq/buss02.png" alt="교육 지원" />
                        </div>
                        <div class="txt">
                            <h3>교육 지원</h3>
                            <p>
                                교육 사각지대에 있는 아이들이 배움을 이어 나갈 수 있도록 <br />
                                학원비, 학용품, 도서 등을 지원합니다.
                            </p>
                        </div>
                    </div>
                </section>
                <h2 class="sub_custom_ttl">희망나무 사회공헌사업 연혁</h2>
                <section class="social_02">
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/BbvQqryB/social-02.png" alt="희망나무 사회공헌사업 연혁" />
                        </div>
                        <div class="txt">
                            <h4>문화교류행사</h4>
                            <ul>
                                <li>2023.01.16 2030 청년들과 함께한 독서모임</li>
                                <li>2023.01.02 법무부 비행청소년 바른길 인도를 위한 작은음악회 문화교류 행사</li>
                                <li>2022.12.29 법무부 비행청소년을 위한 작은음악회 문화교류 행사</li>
                                <li>2022.12.25 서울시립 은평의 마을 크리스마스 기념 장애인 행사</li>
                                <li>2022.12.21 저소득층 가족 미술문화교류</li>
                                <li>2022.12.18 서울시립 은평의 마을 생일축하 음악회</li>
                                <li>2022.11.26 서울시립 은평의 마을,은혜로운 집, 평화로운 집 작은 음악회</li>
                                <li>2022.11.12 서울시립 은평의 마을 사랑의 붕어빵 행사</li>
                                <li>2022.10.23 구세군 서울 후생원 아이를 위한 문화행사: 작은 음악회, 밥봉사,물품지원</li>
                                <li>2022.09.07 법무부 청소년 범죄예방위한 사랑의 송편 행사</li>
                                <li>2022.07.26 서울 장애인 자립센터 어울림 음악회</li>
                                <li>2022.05.24 서울시립 은평의마을 작은 음악회 및 기부행사</li>
                                <li>2022.04.27 작은 음악회 및 물품지원행사</li>
                                <li>2022.04.21 대전시 동구 청소년 지원 문화체험 및 나눔활동</li>
                                <li>2022.04.03 서울시립 은평의 마을 작은 음악회 및 물품지원행사</li>
                                <li>2022.02.19 소년소녀 가장을 위한 작은 음악회 및 김치나눔</li>
                                <li>2021.12.31 수진사 자비원 작은 음악회 및 물품지원: 건강나눔행사</li>
                            </ul>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/pr1Vy0Mb/social-03.png" alt="희망나무 사회공헌사업 연혁" />
                        </div>
                        <div class="txt">
                            <h4>문화교류아카데미</h4>
                            <ul>
                                <li>2022.10.13 한부모가정과 함께하는 문화예술아카데미: 한부모 가정 아동후원행사</li>
                            </ul>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/9XxQxfR8/social-04.png" alt="희망나무 사회공헌사업 연혁" />
                        </div>
                        <div class="txt">
                            <h4>비대면 청년 문화교류행사</h4>
                            <ul>
                                <li>비대면 청년 문화교류행사 &lt;청춘남녀 문화교류 온라인 행사 10회&gt;</li>
                                <li>2021.04.03 청년 프로젝트(음악회 관람)청춘남녀 문화교류 온라인 행사 1회</li>
                                <li>2021.04.17 청년 프로젝트(뮤지컬 관람)청춘남녀 문화교류 온라인 행사 2회</li>
                                <li>2021.05.01 청년 프로젝트(한강 산책)청춘남녀 문화교류 온라인 행사3회</li>
                                <li>2021.05.08 청년 프로젝트(연극 관람)청춘남녀 문화교류 온라인 행사 4회</li>
                                <li>2021.05.22 청년 프로젝트(전시 관람)청춘남녀 문화교류 온라인 행사 5회</li>
                                <li>2021.07.02 청년 프로젝트(박물관 관광)청춘남녀 문화교류 온라인 행사 6회</li>
                                <li>2021.07.09 청년 프로젝트(영화 관람)청춘남녀 문화교류 온라인 행사 7회</li>
                                <li>2021.08.19 청년 프로젝트(야구 관람)청춘남녀 문화교류 온라인 행사 8회</li>
                                <li>2021.11.25 청년 프로젝트(서울 주요 관광지 구경)청춘남녀 문화교류 온라인 행사 9회</li>
                                <li>2022.01.20 청년 프로젝트(별마당 독서)청춘남녀 문화교류 온라인 행사 10회</li>
                                <br />
                                <li>&lt;청년교류 이벤트&gt;</li>
                                <li>2021.06.26 시골청년 동안 프로젝트</li>
                                <li>2021.09.09 미혼 변호사에게 무엇이든 물어보세요.(법률 상담 및 개인 질문)</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div> */}
        </div>
    );
}

export default Social;
