import { padding } from "@mui/system";

export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    color: "#333",
                    fontWeight: "400"
                },
                title: {
                    fontSize: "1.125rem"
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "0",
                    "&:last-child": {
                        paddingBottom: "0"
                    }
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    height: "100%",
                    "@media (min-width: 1200px)": {
                        paddingLeft: "0",
                        paddingRight: "0",
                        maxWidth: "1171px"
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: "4px",
                    padding: "0",
                    "&:MuiButton-sizeMedium": {
                        padding: "5px 10px"
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#333",
                    textDecoration: "none",
                    fontFamily: `'Pretendard', sans-serif`
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none"
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: "24px"
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    "&.Mui-selected": {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        "&:hover": {
                            backgroundColor: theme.menuSelectedBack
                        },
                        "& .MuiListItemIcon-root": {
                            color: theme.menuSelected
                        }
                    },
                    "&:hover": {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        "& .MuiListItemIcon-root": {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: "36px"
                }
            }
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    border: "1px solid #33333380",
                    minWidth: "36px"
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&:hover ": {
                        background: "#212121"
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    "&.Mui-expanded": {
                        margin: "0px",
                        "&:before ": {
                            opacity: "1"
                        }
                    },
                    "&:before ": {
                        backgroundColor: "rgba(0, 0, 0, 0.6)"
                    },
                    "&:first-of-type": {
                        borderTop: "1px solid rgba(0, 0, 0, 0.6)",
                        borderRadius: "0"
                    },
                    "&:last-of-type": {
                        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
                        borderRadius: "0",
                        "&.Mui-expanded": {
                            borderBottom: "unset"
                        }
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: "0px",
                    minHeight: "58px",
                    "&.Mui-expanded": {
                        minHeight: "58px",
                        margin: "unset"
                    },
                    "&:hover ": {
                        background: "unset"
                    }
                },
                content: {
                    margin: "0px",
                    "&.Mui-expanded": {
                        margin: "unset"
                    }
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    "&::placeholder": {
                        color: theme.darkTextSecondary,
                        fontSize: "0.875rem"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    borderRadius: "0",
                    "& .MuiOutlinedInput-notchedOutline": {
                        display: "none"
                    },
                    "&:hover $notchedOutline": {
                        borderColor: "rgba(33,33,33,0.26)"
                    },
                    "&.MuiInputBase-multiline": {
                        padding: 1
                    },
                    "&.MuiInputBase-inputSizeSmall": {
                        padding: "80px 14px"
                    }
                },
                input: {
                    padding: "18px 12px",
                    width: "100%",
                    border: "1px solid #ebebeb",
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    "&.MuiInputBase-inputSizeMedium": {
                        padding: "20px 14px"
                    },
                    "&.MuiInputBase-inputSizeSmall": {
                        padding: "17.5px 14px",
                        "&.MuiInputBase-inputAdornedStart": {
                            paddingLeft: 0
                        }
                    },
                    "&:focus": {
                        borderColor: "#469e54"
                    },
                    "&:-webkit-autofill": {
                        borderRadius: "5px"
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: "0"
                }
            }
        }
    };
}
