import React from "react";

function Regular() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn regular">
                <h2 class="sub_conts_ttl">
                    희망나무재단은 1인 가구의 사회적 고립문제를 해결하기 위해 <br class="no_mo" /> 문화예술을 매개체로 교류할 수 있는
                    아카데미 사업을 지원하고 있습니다.
                </h2>
                <section class="regular_01 sub_container">
                    <h3 class="sub_sec_ttl">희망나무재단의 강점</h3>
                    <div class="regular_01_cont">
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/nVYFgbC6/strength01.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>국내외 협력기관 구축</h4>
                                <p>
                                    국내 2,803개소, 해외 13개국의
                                    <br /> 국가와 협력관계를 구축하여 <br />
                                    소외된 이웃들을 위한 활동을
                                    <br /> 수행하고 있습니다.
                                </p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/WpXR02Db/strength02.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>투명한 NGO</h4>
                                <p>
                                    외부 회계법인으로부터 <br />
                                    정기적 감사를 통해 기부금의
                                    <br /> 사용처를 투명하게
                                    <br /> 공개하고 있습니다.
                                </p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/vHVzbhdF/strength03.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>UN 특별 협의적 지위 취득</h4>
                                <p>
                                    UN 특별협의적 지위를 취득한
                                    <br /> 기관으로 전 세계 사회문제 해결을 위해
                                    <br /> 투명성과 책무성을 기반으로 한<br /> 전문성을 갖춘 기관입니다.
                                </p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/YS98KHr8/strength04.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>맞춤형 사회공헌 사업 기획</h4>
                                <p>
                                    기업의 가치와 방향에 <br /> 맞는 사회공헌 컨설팅을
                                    <br /> 통해 맞춤형 사회공헌 사업을
                                    <br /> 기획합니다.
                                </p>
                            </article>
                        </div>
                    </div>
                </section>
                <section class="regular_02 ">
                    <h3 class="container">
                        <span>소중한 후원금,</span> 이렇게 사용됩니다.
                    </h3>
                    <div class="regular_02_cont container">
                        <p>소외된 계층과 희망을 함께 만들어가는 사회공헌사업</p>
                        <article>
                            <div class="item">
                                <h4>청년문화교류</h4>
                                <p>
                                    1인가구 청년 교류를 위한 다양한
                                    <br /> 문화행사 프로그램을 진행합니다.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/02jMkvyq/4.png" alt="청년문화교류" />
                                </div>
                            </div>
                            <div class="item">
                                <h4>아동 교육, 심리, 의료 지원</h4>
                                <p>
                                    소외된 아동들을 위해 심리정서, <br />
                                    교육, 의료 등 다양한 분야의 지원에 <br />
                                    힘쓰고 있습니다.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/xTGx2BtY/5.png" alt="청년문화교류" />
                                </div>
                            </div>
                            <div class="item">
                                <h4>사랑나눔지원</h4>
                                <p>
                                    1인가구 및 소외된 이웃들을 위해 <br />
                                    공연, 전시 등 다양한 문화예술행사를 <br />
                                    진행합니다.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/x1t6kHw4/6.png" alt="청년문화교류" />
                                </div>
                            </div>
                            <div class="item">
                                <h4>공동모금기부</h4>
                                <p>
                                    소외된 이웃들을 위해 다양한 물품 및 <br />
                                    기부금 후원과 봉사활동을 진행합니다.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/1zXJDXP5/7.png" alt="청년문화교류" />
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                <section class="regular_03 container">
                    <h3>
                        <span>정기후원자가</span> 되어주세요.
                    </h3>
                    <article>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/pX1JgDLb/10.png" alt="정기후원자" />
                            </div>
                            <div class="txt">
                                <h4>정기 소식지/연보</h4>
                                <p>
                                    시기별 사업소식(소식지, 뉴스레터)
                                    <br /> 및 사업보고서를 보내드립니다.
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/FHMgKNST/11.png" alt="정기후원자" />
                            </div>
                            <div class="txt">
                                <h4>후원 결과보고 (상시)</h4>
                                <p>방송/온라인 캠페인 결과보고서 및 기타 사업 현장의 생생한 변화 이야기를 알려드립니다.</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/FK0bd8gN/12.png" alt="정기후원자" />
                            </div>
                            <div class="txt">
                                <h4>후원자 초청 행사</h4>
                                <p>나눔 음악회, 봉사활동, 후원자모임 등 초청 행사에 후원자님을 초대합니다.</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/ZRZ8rHV3/13.png" alt="정기후원자" />
                            </div>
                            <div class="txt">
                                <h4>기부금 영수증</h4>
                                <p>나의 후원 또는 국세청 연말정산간소화 서비스에서 기부금영수증을 발급 받을 수 있습니다.</p>
                            </div>
                        </div>
                    </article>
                </section>
                <section class="regular_04">
                    <h3 class="container">후원진행절차</h3>
                    <article class="container">
                        <div class="top">
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/3r0GdPK6/20.png" alt="후원진행절차" />
                                </div>
                                <div class="txt">
                                    <h4>STEP 01</h4>
                                    <p>문의하기</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/7Yv27mhL/21.png" alt="후원진행절차" />
                                </div>
                                <div class="txt">
                                    <h4>STEP 02</h4>
                                    <p>후원신청</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/c4Q8cwtB/22.png" alt="후원진행절차" />
                                </div>
                                <div class="txt">
                                    <h4>STEP 03</h4>
                                    <p>후원신청 방법선택</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/mgjzv4wc/23.png" alt="후원진행절차" />
                                </div>
                                <div class="txt">
                                    <h4>STEP 04</h4>
                                    <p>후원일자 및 전달방식 선택</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/CKKBY2Pc/24.png" alt="후원진행절차" />
                                </div>
                                <div class="txt">
                                    <h4>STEP 05</h4>
                                    <p>후원전달</p>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li>
                                [법인세법시행령] 제 39조 제 1항 11호에 따른 기획재정부장관이 지정하는 공익법인이며, 납부하신 후원금은
                                법인세법 제 24조와 소득세법 제 34조에 의하여 연말정산 시 소득공제 혜택을 받으실 수 있습니다.
                            </li>
                            <li>연말정산 소득공제 혜택을 위한 기부금영수증을 발급해 드립니다.</li>
                        </ul>
                    </article>
                </section>
            </div>
        </div>
    );
}

export default Regular;
