import { CircularProgress } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const getSizeStyles = (size) => {
    const sizes = {
        sm: { width: "152px", height: "37px", fontSize: "14px" },
        md: { width: "200px", height: "52px", fontSize: "18px" },
        lg: { width: "250px", height: "62px", fontSize: "22px" },
        xl: { width: "300px", height: "72px", fontSize: "26px" }
    };

    return sizes[size] || {};
};

export const CustomButton = ({ link, text, size }) => {
    const style = {
        width: "200px",
        height: "52px",
        fontSize: "18px",
        lineHeight: "1",
        ...getSizeStyles(size)
    };

    return (
        <>
            <Link to={link} className="custom_button" style={style}>
                {text}
            </Link>
        </>
    );
};

export const CustomAtagButton = ({ link, text, size, isBlank }) => {
    const style = {
        width: "200px",
        height: "52px",
        fontSize: "18px",
        lineHeight: "1",
        ...getSizeStyles(size)
    };

    return (
        <>
            <a href={link} className="custom_button" style={style} target={isBlank ? "_blank" : ""} rel="noopener noreferrer">
                {text}
            </a>
        </>
    );
};

export const CustomSubmitButton = ({ link, text, size, onClick, disabled, loading }) => {
    const style = {
        width: "200px",
        height: "52px",
        fontSize: "18px",
        lineHeight: "1",
        ...getSizeStyles(size)
    };

    return (
        <>
            <button type="button" onClick={onClick} className="custom_button" style={style} disabled={disabled}>
                {loading && <CircularProgress size={20} />} {text}
            </button>
        </>
    );
};
