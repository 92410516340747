import Loadable from "components/Loadable";
import NotFound from "components/notFound";

import Greeting from "publishing/greeting";
import Pub_about from "publishing/pub_about";
import Tissue from "publishing/tissue";
import Esg from "publishing/esg";
import Management from "publishing/management";
import Business from "publishing/business";

import { lazy } from "react";
import Pages from "store/pages";
import CommunityConfig from "views/pages/community";
import About from "views/pages/company/about";
import Certificate from "views/pages/company/certificate";
import Location from "views/pages/company/location";
import Point from "views/pages/company/point";
import MnGallery from "views/pages/outsourcing/mn/gallery";
import MnStaff from "views/pages/outsourcing/mn/staff";
import Portfolio from "views/pages/portfolio/portfolio";
import Sponsor from "views/pages/sponsor/sponsor";
import Kakao from "views/pages/solution/kakao";
import OutsourcingSolution from "views/pages/solution/outsourcing";
import Rcs from "views/pages/solution/rcs";
import Sms from "views/pages/solution/sms";
import Address from "publishing/address";
import PrivacyPolicy from "views/pages/terms/privacypolicy";
import Ethical from "publishing/ethical";
import Social from "publishing/social";
import Youth from "publishing/youth";
import Temprorary from "publishing/temporary";
import Regular from "publishing/regular";
import Terms from "views/pages/terms/terms";
import SponsorComplete from "views/pages/sponsor/sponsorComplete";

const Main = Loadable(lazy(() => import("views/pages/main/main")));
const DynamicPage = Loadable(lazy(() => import("views/pages/dynamic/page")));
const GalleryDetail = Loadable(lazy(() => import("views/pages/outsourcing/galleryDetail")));

const MainRoutes = [
    // MAIN
    { path: Pages.MAIN, element: <Main /> },

    // COSTUMER SERVICE
    { path: "", children: [...CommunityConfig.routes] },
    { path: "/web/contents/:id", element: <DynamicPage /> },

    // STATIC PAGES
    { path: "/mn/outsourcing/staff", element: <MnStaff /> },
    { path: "/staffs", element: <MnStaff /> },
    { path: "/mn/outsourcing/gallery", element: <MnGallery /> },
    { path: "/mn/outsourcing/gallery/:id", element: <GalleryDetail /> },

    // PORTFOLIO
    { path: "/portfolio", element: <Portfolio /> },

    // SPONSOR
    { path: "/sponsor", element: <Sponsor /> },
    { path: "/complete", element: <SponsorComplete /> },

    // Terms
    { path: "/privacypolicy", element: <PrivacyPolicy /> },
    { path: "/terms", element: <Terms /> },

    // SOLUTION
    { path: "/solution/sms", element: <Sms /> },
    { path: "/solution/kakao", element: <Kakao /> },
    { path: "/solution/rcs", element: <Rcs /> },
    { path: "/solution/outsourcing", element: <OutsourcingSolution /> },

    // COMPANY
    { path: "/about", element: <About /> },
    { path: "/point", element: <Point /> },
    { path: "/location", element: <Location /> },
    { path: "/company/certificates", element: <Certificate /> },

    // publish
    // { path: "/pub_about", element: <Pub_about /> },
    // { path: "/greeting", element: <Greeting /> },
    // { path: "/tissue", element: <Tissue /> },
    // { path: "/esg", element: <Esg /> },
    // { path: "/management", element: <Management /> },
    // { path: "/address", element: <Address /> },
    // { path: "/business", element: <Business /> },
    { path: "/ethical", element: <Ethical /> },
    { path: "/social", element: <Social /> },
    { path: "/youth", element: <Youth /> },
    { path: "/temprorary", element: <Temprorary /> },
    { path: "/regular", element: <Regular /> },

    // 404 Not Found 페이지
    { path: "*", element: <NotFound /> }
];

export default MainRoutes;
