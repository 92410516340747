import React, { useEffect, useState } from "react";
import { useCertListQuery } from "api/community/community";
import { SERVER_URL } from "config";
import Loader from "components/Loader";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Certificate() {
    const currentLanguage = i18next.language;
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [modalCaption, setModalCaption] = useState("");

    const [options, setOptions] = useState({ page: 0, size: 12, searchWrd: "" });

    const { data, isLoading, isFetching, refetch } = useCertListQuery(new URLSearchParams({ ...options }).toString());

    useEffect(() => {
        refetch();
    }, []);

    const handlePageChange = (page) => setOptions((prevState) => ({ ...prevState, page: page - 1 }));
    const handleFirstPageChange = () => setOptions((prevState) => ({ ...prevState, page: 0 }));
    const handleLastPageChange = () => setOptions((prevState) => ({ ...prevState, page: data?.totalPages - 1 }));
    const handlePrevPageChange = () => setOptions((prevState) => ({ ...prevState, page: options.page > 0 ? options.page - 1 : 0 }));
    const handleNextPageChange = () =>
        setOptions((prevState) => ({ ...prevState, page: options.page + 1 != data?.totalPages ? options.page + 1 : data?.totalPages - 1 }));
    const numbers = Array.from({ length: data?.totalPages }, (_, index) => index + 1);

    const numberOfPages = numbers.map((number) => (
        <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={"paging_number " + (number == options.page + 1 ? "pag_active" : "")}
        >
            {number}
        </button>
    ));

    const openModal = (imageSrc, caption) => {
        setIsModalOpen(true);
        setModalImage(imageSrc);
        setModalCaption(caption);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="sub-container">
            {(isLoading || isFetching) && <Loader />}
            <div className="sub_conts_inn cert_inn">
                <div className="cert_items">
                    {data &&
                        data.content?.map((item, index) => (
                            <div
                                key={index}
                                className="item cursor-pointer"
                                onClick={() =>
                                    openModal(
                                        SERVER_URL + item.attachments[0]?.url,
                                        currentLanguage === "kr" || !item.certNameEn ? item.certName : item.certNameEn
                                    )
                                }
                            >
                                <figure>
                                    <img src={SERVER_URL + item.attachments[0]?.url} alt="awards" />
                                </figure>
                                <p>{currentLanguage === "kr" || !item.certNameEn ? item.certName : item.certNameEn}</p>
                            </div>
                        ))}
                </div>
                <div>
                    {isModalOpen && (
                        <div id="modal" className="modal" style={{ display: "block" }}>
                            <div className="modal-content">
                                <div className="title-cont">
                                    <div className="inner-cont">
                                        <button>인증서</button>
                                        <h4 id="title">{modalCaption}</h4>
                                    </div>
                                    <p className="close" onClick={closeModal}>
                                        CLOSE<span>&times;</span>
                                    </p>
                                </div>
                                <img src={modalImage} className="modal-img" id="img01" alt="popup" />
                            </div>
                        </div>
                    )}
                </div>
                {data && (
                    <div className="paging_wrap">
                        <div className="paging_conts">
                            <button onClick={() => handleFirstPageChange()} className="paging_start"></button>
                            <button onClick={() => handlePrevPageChange()} className="paging_prev"></button>
                            {numberOfPages}
                            <button onClick={() => handleNextPageChange()} className="paging_next"></button>
                            <button onClick={() => handleLastPageChange()} className="paging_end"></button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
