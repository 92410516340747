import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="not_found">
            <div>
                <h1 className="icon">
                    4<span className="">😥</span>4
                </h1>
            </div>
            <p>
                죄송합니다.
                <br />
                요청하신 페이지를 찾을 수 없습니다.
            </p>
            <Link to="/">홈 페이지로 이동</Link>
        </div>
    );
};

export default NotFound;
