import { useSolutionSmsListQuery } from "api/solution";
import { CustomAtagButton, CustomButton } from "components/buttons/CustomButton";
import { SERVER_URL } from "config";
// icon
import Loader from "components/Loader";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Info from "./info";

export default function SMS() {
    const currentLanguage = i18next.language;
    const { t } = useTranslation(); // can't delete it
    const { data, isLoading, isFetching, refetch } = useSolutionSmsListQuery();
    return (
        <>
            {isLoading && <Loader />}
            <div className="sub-container">
                <div className="sub_conts_inn solution_inn">
                    <h2>{currentLanguage === "kr" || !data?.solutionTitleEn ? data?.solutionTitle : data?.solutionTitleEn}</h2>
                    <div className="solution_info">
                        <div className="img">
                            <img src={SERVER_URL + data?.attachments[0]?.url} alt={data?.solutionTitle} />
                        </div>
                        <div className="info">
                            <h3>
                                {Number(data?.solutionPrice).toLocaleString()}{t("won")}<span>{t("vat_included")}</span>
                            </h3>
                            <article>
                                <div>
                                <p>{t("preferences")}</p>
                                    <span>{data?.solutionDev}</span>
                                </div>
                                <div>
                                    <p>A/S</p>
                                    <span>{currentLanguage === "kr" || !data?.solutionAsEn ? data?.solutionAs : data?.solutionAsEn}</span>
                                </div>
                                <div>
                                <p>{t("coding_type")}</p>
                                    <span>
                                        {currentLanguage === "kr" || !data?.solutionCodingEn
                                            ? data?.solutionCoding
                                            : data?.solutionCodingEn}
                                    </span>
                                </div>
                                <div>
                                <p>{t("licence")}</p>
                                    <span>
                                        {currentLanguage === "kr" || !data?.solutionLicenseEn
                                            ? data?.solutionLicense
                                            : data?.solutionLicenseEn}
                                    </span>
                                </div>
                                <div>
                                   <p>{t("encoding")}</p>
                                    <span>{data?.solutionEncoding}</span>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="solution_demo">
                        <CustomAtagButton text={t("user_demo")} link={data?.solutionUserUrl} isBlank />
                        <CustomAtagButton text={t("admin_demo")} link={data?.solutionAdmUrl} isBlank />
                        <CustomButton
                            text={t("quote_consultation")}
                            link={"/community/contact/create?contactCd=solution&title=" + data?.solutionTitle}
                        />
                    </div>
                    <div className="solution_desc">
                        <h4 className="solution_cont_tit">{t("service_intro")}</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: currentLanguage === "kr" || !data?.solutionDescEn ? data?.solutionDesc : data?.solutionDescEn
                            }}
                        />
                    </div>
                    <div className="solution_ss sub-container">
                        <h4 className="solution_cont_tit">{t("main_function")}</h4>
                        {data &&
                            data?.attachmentsDetail?.map((img, i) => (
                                <div className="detail_img" key={i}>
                                    <img src={SERVER_URL + img?.url} alt={data?.solutionTitle} />
                                </div>
                            ))}
                    </div>
                    <div></div>
                </div>
            </div>
            <Info />
        </>
    );
}
