import React, { forwardRef, useEffect, useState } from "react";
import { Box, FormHelperText, OutlinedInput, Skeleton, Typography } from "@mui/material";
import { addComma } from "utils/functions/common";

const TextInputField = forwardRef(
    ({ sx, isLoading, placeholder, onChange, onBlur, error, width, moWidth, height, size, label, type, disabled, ...props }, ref) => {
        const [val, setVal] = useState("");

        useEffect(() => {
            if (type === "money") {
                const formattedValue = addComma(props.value);
                setVal(formattedValue);
            } else {
                setVal(props.value || "");
            }
        }, [props.value, type]);

        const handleChange = (e) => {
            const { value } = e.target;
            let formattedValue = value;

            if (type === "money") {
                formattedValue = value.replace(/\D/g, "");
                formattedValue = addComma(formattedValue);
            } else if (type === "number") {
                formattedValue = value.replace(/\D/g, "");
            } else if (type === "text") {
                formattedValue = value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z]/g, "");
            }

            setVal(formattedValue);
            if (onChange) {
                onChange({ ...e, target: { ...e.target, value: formattedValue } });
            }
        };

        return isLoading ? (
            <Skeleton animation="wave" width={width || "200px"} height={height || "25px"} />
        ) : (
            <Box
                width={width || "100%"}
                textAlign={"start"}
                size="small"
                sx={{
                    "@media (max-width: 500px)": {
                        width: moWidth ? moWidth : "100%"
                    }
                }}
            >
                {label && <Typography>{label}</Typography>}
                <OutlinedInput
                    ref={ref}
                    error={props?.erros}
                    onChange={handleChange}
                    value={val || val}
                    size={size || "small"}
                    placeholder={placeholder || ""}
                    onBlur={onBlur}
                    sx={{
                        width: width ? width : "100%",
                        "@media (max-width: 500px)": {
                            width: moWidth ? moWidth : "100%"
                        },
                        ...sx
                    }}
                    inputProps={{
                        maxLength: props?.maxLength || 255,
                        style: { textAlign: type === "money" ? "end" : "", borderColor: error ? "red" : "" }
                    }}
                    disabled={disabled}
                    {...props}
                />
                <FormHelperText error>{error}</FormHelperText>
            </Box>
        );
    }
);

export default TextInputField;
