import { useGetSiteInfoQuery } from "api/main/main";

const PrivacyPolicy = () => {
    const { data: siteInfo, loading } = useGetSiteInfoQuery();

    return (
        <div className="sub_container privacy_policy">
            <h1 className="sub_conts_ttl_no_banner">개인정보처리방침</h1>
            <div
                dangerouslySetInnerHTML={{
                    __html: siteInfo?.content?.privacyPolicy
                }}
            />
        </div>
    );
};

export default PrivacyPolicy;
