import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const SolutionApi = createApi({
    reducerPath: "SolutionApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Solution"],
    endpoints: (build) => ({
        solutionSmsList: build.query({
            query: () => `${URL.SOLUTION_SMS}`,
            providesTags: ["SOLUTION"]
        }),
        solutionRcsList: build.query({
            query: () => `${URL.SOLUTION_RCS}`,
            providesTags: ["SOLUTION"]
        }),
        solutionKakaoList: build.query({
            query: () => `${URL.SOLUTION_KAKAO}`,
            providesTags: ["SOLUTION"]
        }),
        solutionOutsourcingList: build.query({
            query: () => `${URL.SOLUTION_OUTSOURCING}`,
            providesTags: ["SOLUTION"]
        })
    })
});

export const { useSolutionSmsListQuery, useSolutionRcsListQuery, useSolutionKakaoListQuery, useSolutionOutsourcingListQuery } = SolutionApi;
