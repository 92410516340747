function Youth() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn youth">
                <section class="youth_01 container">
                    <div class="img">
                        <img src="https://i.postimg.cc/5y602PFJ/15-1.png" alt="문화교류 청년아카데미" />
                    </div>
                    <div class="txt">
                        <h3>문화교류 청년아카데미</h3>
                        <p>
                            사람과 사람, 세대와 세대 연결이 필요한 1인가구의
                            <br /> 사회적 고립 문제 해결을 위해 다양한 문화교류프로그램을
                            <br />
                            진행함으로써 행복을 심어주고 나눔을 실천하고 있습니다.
                        </p>
                    </div>
                </section>
                <section class="youth_02">
                    <div class="youth_02_inn">
                        <div class="youth_head">
                            <h4>마음과 마음을 잇는</h4>
                            <h5>잇다 청년아카데미</h5>
                            <h6>
                                <span>[마음:잇다]</span>
                                커리큘럼 안내
                            </h6>
                        </div>
                        <div class="youth_body">
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/t4q2BfwD/1-1.png" alt="청년아카데미" />
                                </div>
                                <div class="txt">
                                    <h4>문화예술소통 프로젝트</h4>
                                    <p>문화예술을 활용하여 참가자들 간 대화를 이끄는 소통 프로젝트</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/nLhJv3nQ/2-2.png" alt="청년아카데미" />
                                </div>
                                <div class="txt">
                                    <h4>셀프케어 프로젝트</h4>
                                    <p>자기 관리를 위한 셀프케어 프로젝트</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/KjnwXTZ2/3-3.png" alt="청년아카데미" />
                                </div>
                                <div class="txt">
                                    <h4>재정관리 프로젝트</h4>
                                    <p>1인 가구의 경제적인 안정과 미래를 준비할 수 있는 재정 관리 프로젝트</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img src="https://i.postimg.cc/5NSTn875/4-1.png" alt="청년아카데미" />
                                </div>
                                <div class="txt">
                                    <h4>동행 프로젝트</h4>
                                    <p>공연, 전시, 관람, 여행 등 함께 다양한 문화 예술 체험을 하는 동행 프로젝트</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Youth;
