import React from "react";
import { Button, Modal, Typography } from "@mui/material";
import { ModalInner } from "./ModalInner";
import ButtonWrapp from "components/buttons/ButtonWrapp";
import { LoadingButton } from "@mui/lab";

export const ConfirmModal = ({ open, close, onClick, text, title, loading }) => {
    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner>
                <Typography variant="h4" sx={{ borderBottom: "1px solid #b7b7b7", paddingBottom: "10px" }}>
                    {title}
                </Typography>
                <Typography id="parent-modal-description" variant="h4" sx={{ textAlign: "center", margin: "30px 0" }}>
                    {text}
                </Typography>
                <ButtonWrapp sx={{ justifyContent: "center" }}>
                    <Button
                        disableElevation
                        variant="outlined"
                        color="primary"
                        disabled={loading}
                        onClick={close}
                        sx={{ padding: "5px 30px" }}
                    >
                        취소
                    </Button>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        loadingPosition="start"
                        variant="contained"
                        onClick={onClick}
                        sx={{ padding: "5px 30px" }}
                        autoFocus
                    >
                        확인
                    </LoadingButton>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
};
