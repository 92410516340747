import { MainConfig, CommunityConfig } from "views/pages";

import { CommonCodeFilterApi } from "api/commonCode";
import { BannerApi } from "api/main/banner";
import { SolutionApi } from "api/solution";
import { SponsorApi } from "api/sponsor";

export const Middlewares = [
    MainConfig.API.middleware,
    CommunityConfig.API.middleware,
    CommonCodeFilterApi.middleware,
    SolutionApi.middleware,
    SponsorApi.middleware,
    BannerApi.middleware
];
