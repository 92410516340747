import { CustomAtagButton, CustomButton, CustomSubmitButton } from "components/buttons/CustomButton";

const SpponsorComplete = () => {
    return (
        <div className="sponsor_complete container">
            <h1>후원신청을 축하드립니다. </h1>
            <CustomButton link="/" text={"메인 페이지로 이동"} />
        </div>
    );
};

export default SpponsorComplete;
