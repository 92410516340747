import React from "react";

function Temprorary() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn regular temprorary">
                <section class="temprorary_01">
                    <h2>작은 나눔, 더 큰 기적</h2>
                    <p>
                        한번의 나눔 실천으로,
                        <br />
                        우리사회 소외된 이웃에게 큰 힘을 넣어줍니다.
                    </p>
                </section>
                <section class="temprorary_02 container">
                    <h3>소액 후원</h3>
                    <div class="temprorary_02_cont ">
                        <p>
                            후원의 마음은 있지만 실천하기
                            <br />
                            어려우시다면, 소액후원으로 <br />
                            부담없이 시작해 보세요.
                        </p>
                        <article>
                            <div class="item">
                                <h4>작은 나눔의 첫걸음</h4>
                                <p>
                                    작은 나눔으로 모인 후원금은
                                    <br />
                                    우리사회 소외된 이웃에게 전달됩니다.
                                    <br />
                                    <br />
                                    작지만 큰마음
                                    <br />
                                    지금 바로 시작해 보세요.
                                </p>
                                <a href="">작은 나눔 참여하기</a>
                                <div>
                                    <img src="https://i.postimg.cc/JhjgrTsB/31.png" alt="청년문화교류" />
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                <section class="temprorary_03 regular_02">
                    <h3 class="container">
                        <span>기념일 후원</span>
                    </h3>
                    <div class="regular_02_cont container">
                        <p>
                            생일, 결혼, 출산, 입학, 취업 등<br />
                            여러분의 소중하고 특별한 날을
                            <br />
                            더욱 가치있게 기념해 보세요.
                        </p>
                        <article>
                            <div class="item">
                                <h4>생일</h4>
                                <p>
                                    1년에 한번 소중한 생일을 맞이하여
                                    <br />
                                    주변의 사람들과 뜻깊게 기념해요.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/MHHdQtp5/32.png" alt="기념일 후원" />
                                </div>
                            </div>
                            <div class="item">
                                <h4>결혼</h4>
                                <p>
                                    결혼 및 결혼기념일을 맞이하여
                                    <br />
                                    더욱 아름답게 기념해요.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/8cYwBWFS/33.png" alt="기념일 후원" />
                                </div>
                            </div>
                            <div class="item">
                                <h4>입학·졸업</h4>
                                <p>
                                    새로운 시작과 마지막 순간을
                                    <br />
                                    함께 나누며 기념해요.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/ZYsfSkwM/34.png" alt="기념일 후원" />
                                </div>
                            </div>
                            <div class="item">
                                <h4>임신·출산</h4>
                                <p>
                                    소중한 새 생명 탄생의 순간을
                                    <br />
                                    의미 있게 기념해요.
                                </p>
                                <div>
                                    <img src="https://i.postimg.cc/m2cmZdq2/35.png" alt="기념일 후원" />
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Temprorary;
