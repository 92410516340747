import { useGetSiteInfoQuery } from "api/main/main";

const Terms = () => {
    const { data: siteInfo, loading } = useGetSiteInfoQuery();

    return (
        <div className="sub_container privacy_policy">
            <h1 className="sub_conts_ttl_no_banner">이용약관</h1>
            <div
                dangerouslySetInnerHTML={{
                    __html: siteInfo?.content?.termsUse
                }}
            />
        </div>
    );
};

export default Terms;
