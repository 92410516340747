import { useGetCommonFilterCodeQuery } from "api/commonCode";
import { useStaffCeoQuery, useStaffCtoQuery, useStaffListQuery, useStaffViceQuery, useStaffViceTwoQuery } from "api/community/community";
import Loader from "components/Loader";
import { SERVER_URL } from "config";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function MnStaff() {
    const [options, setOptions] = useState({});

    const { data, isLoading, isFetching, refetch } = useStaffListQuery(new URLSearchParams({ ...options }).toString());
    const { data: ceoData, isCeoLoading } = useStaffCeoQuery();
    const { data: ctoData, isCtoLoading } = useStaffCtoQuery();
    const { data: viceData, isViceLoading } = useStaffViceQuery();
    const { data: viceTwoData, isViceTwoLoading } = useStaffViceTwoQuery();
    const { data: staffCategories } = useGetCommonFilterCodeQuery("STAFF_POSITION");
    const { t } = useTranslation();

    useEffect(() => {
        refetch();
    }, []);

    console.log("data", data);

    return (
        <div className="sub_conts">
            {(isLoading || isFetching || isCeoLoading || isCtoLoading || isViceLoading || isViceTwoLoading) && <Loader />}
            <div className="sub_conts_inn">
                <section className="sub_staff_one">
                    {ceoData && (
                        <div className="box w-full">
                            <div className="left">
                                <div className="ceo_txt">
                                    <h1>
                                        {ceoData?.name} <span>{t("ceo")}</span>
                                        <br />
                                        <span>{t("dev_it")}</span> {t("experience")}
                                        {ceoData?.experience}
                                        {t("years")}
                                    </h1>
                                </div>
                                <div className="ceo">
                                    <div>{ceoData?.position}</div>
                                    <img src={SERVER_URL + ceoData?.attachments[0]?.url} alt="ceo" className="ceo-img" />
                                </div>
                            </div>
                        </div>
                    )}
                    {viceData && (
                        <div className="box w-full">
                            <div className="right">
                                <div className="ceo_txt">
                                    <h1>
                                        {viceData?.name} <span>{t("vise")}</span>
                                        <br />
                                        <span>{t("amarsanaa_uni")}</span> {t("amarsanaa_depart")}
                                    </h1>
                                </div>
                                <div className="ceo">
                                    <div>{t("vise")}</div>
                                    <img src={SERVER_URL + viceData?.attachments[0]?.url} alt="ceo" className="ceo-img" />
                                </div>
                            </div>
                        </div>
                    )}
                    {viceTwoData && (
                        <div className="box w-full">
                            <div className="left">
                                <div className="ceo_txt">
                                    <h1>
                                        {viceTwoData?.name} <span>{t("vise")}</span>
                                        <br />
                                        <span>{t("temuujin_uni")}</span> {t("temuujin_depart")}
                                    </h1>
                                </div>
                                <div className="ceo">
                                    <div>{t("vise")}</div>
                                    <img src={SERVER_URL + viceTwoData?.attachments[0]?.url} alt="ceo" className="ceo-img" />
                                </div>
                            </div>
                        </div>
                    )}
                    {ctoData && (
                        <div className="box w-full">
                            <div className="right">
                                <div className="ceo_txt">
                                    <h1>
                                        {ctoData?.name} <span>{t("cto")}</span>
                                        <br />
                                        <span>{t("dev_it")}</span> {t("experience")}
                                        {ctoData?.experience}
                                        {t("years")}
                                    </h1>
                                </div>
                                <div className="ceo">
                                    <div>CTO</div>
                                    <img src={SERVER_URL + ctoData?.attachments[0]?.url} alt="ceo" className="ceo-img" />
                                </div>
                            </div>
                        </div>
                    )}
                </section>

                <section className="sub_cont_staff sub-container">
                    {data &&
                        data.map((item, index) => (
                            <div className="staff-info-cont" key={index}>
                                <div className="hover_cont">
                                    <h3>{t("profile")}</h3>
                                    <p className="break-spaces">{item.subDescription}</p>
                                    <h3>{t("profile_skill")}</h3>
                                    <ul>
                                        {item.techLanguages.split(",").map((lang, i) => (
                                            <li key={i}>{lang}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="staff_img">
                                    <img src={SERVER_URL + item.attachments[0]?.url} alt="tagoplus staff" />
                                </div>
                                <div className="info-text">
                                    <h2>{item.name}</h2>
                                    <p className="job-title">
                                        {staffCategories?.data &&
                                            (staffCategories?.data?.filter((code) => code?.code === item.position)[0]?.codeEngNm || "-")}
                                    </p>
                                    <p className="job-exp">
                                        {t("experience")} {item.experience}
                                        {item.experience === "1" ? t("year") : t("years")}
                                    </p>
                                </div>
                            </div>
                        ))}
                </section>
            </div>
        </div>
    );
}
