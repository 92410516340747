// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/NotoSans-light.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/NotoSans-light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/NotoSans-regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/NotoSans-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/NotoSans-medium.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/NotoSans-medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/NotoSans-bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/NotoSans-bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/*-------------------------------------------------------------------
    파일정의: Web fonts
    순서:    fonts
-------------------------------------------------------------------*/

@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 300;
    src: local('Noto Sans light'), local('NotoSans-light'), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');;
  }
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Sans Regular'), local('NotoSans-regular'), url(${___CSS_LOADER_URL_REPLACEMENT_3___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
}
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 500;
    src: local('Noto Sans Medium'), local('NotoSans-medium'), url(${___CSS_LOADER_URL_REPLACEMENT_6___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff');
}
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 700;
    src: local('Noto Sans Bold'), local('NotoSans-bold'), url(${___CSS_LOADER_URL_REPLACEMENT_9___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('woff');
}`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;;;oEAGoE;;AAEpE;IACI,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,+FAAwF;IACxF;0DACgD;EAClD;AACF;IACI,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,mGAA8F;IAC9F;0DACkD;AACtD;AACA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,iGAA2F;IAC3F;0DACiD;AACrD;AACA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,6FAAqF;IACrF;2DAC+C;AACnD","sourcesContent":["@charset \"utf-8\";\n/*-------------------------------------------------------------------\n    파일정의: Web fonts\n    순서:    fonts\n-------------------------------------------------------------------*/\n\n@font-face {\n    font-family: 'NotoSans';\n    font-style: normal;\n    font-weight: 300;\n    src: local('Noto Sans light'), local('NotoSans-light'), url(../fonts/NotoSans-light.eot);\n    src: url(../fonts/NotoSans-light.eot?#iefix) format('embedded-opentype'),\n    url(../fonts/NotoSans-light.woff) format('woff');;\n  }\n@font-face {\n    font-family: 'NotoSans';\n    font-style: normal;\n    font-weight: 400;\n    src: local('Noto Sans Regular'), local('NotoSans-regular'), url(../fonts/NotoSans-regular.eot);\n    src: url(../fonts/NotoSans-regular.eot?#iefix) format('embedded-opentype'),\n    url(../fonts/NotoSans-regular.woff) format('woff');\n}\n@font-face {\n    font-family: 'NotoSans';\n    font-style: normal;\n    font-weight: 500;\n    src: local('Noto Sans Medium'), local('NotoSans-medium'), url(../fonts/NotoSans-medium.eot);\n    src: url(../fonts/NotoSans-medium.eot?#iefix) format('embedded-opentype'),\n    url(../fonts/NotoSans-medium.woff) format('woff');\n}\n@font-face {\n    font-family: 'NotoSans';\n    font-style: normal;\n    font-weight: 700;\n    src: local('Noto Sans Bold'), local('NotoSans-bold'), url(../fonts/NotoSans-bold.eot);\n    src: url(../fonts/NotoSans-bold.eot?#iefix) format('embedded-opentype'),\n    url(../fonts/NotoSans-bold.woff) format('woff');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
