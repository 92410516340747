import React, { useEffect, useRef, useState } from "react";
import { usePortfolioListQuery } from "api/community/community";
import { SERVER_URL } from "config";

export default function Portfolio() {
    const { data, isLoading, isFetching, refetch } = usePortfolioListQuery();
    return (
        <div className="sub-container">
            <div className="sub_conts_inn portfolio_inn">
                <div className="portfolio_items">
                    {data &&
                        data?.content?.map((item) => (
                            <div
                                className="item"
                                key={item.seq}
                                style={{
                                    backgroundImage: `url(${SERVER_URL + item?.attachments[0]?.url})`
                                }}
                            >
                                <h3>
                                    {item.portfolioTitle}
                                    <span>{item.portfolioType}</span>
                                </h3>
                                {/* Detail Info */}
                                <div className="portfolio_det">
                                    <h4>{item.portfolioTitle}</h4>
                                    <p>{item?.portfolioDesc}</p>
                                    <span>{item.portfolioType}</span>
                                    <a href={item.portfolioLink} target="_blank" rel="noopener noreferrer">
                                        link
                                    </a>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
