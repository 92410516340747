import React, { forwardRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, FormHelperText, Skeleton, TextField } from "@mui/material";
import dayjs from "dayjs";

const DefaultDateInput = forwardRef(({ error, ...props }, ref) => {
    return props.loading ? (
        <Skeleton animation="wave" height={60} sx={{ borderRadius: "12px" }} />
    ) : (
        <Box width={"100%"} textAlign={"start"}>
            <DatePicker
                ref={ref}
                name={props.name}
                inputFormat="YYYY-MM-DD"
                format={"YYYY-MM-DD"}
                disabled={props?.disabled || false}
                views={["year", "month", "day"]}
                defaultvalue={props?.value}
                value={props?.value}
                onChange={(date) => {
                    if (date) {
                        const formattedDate = dayjs(date).format("YYYY-MM-DD");
                        props.onChange(formattedDate);
                    } else {
                        props.onChange("");
                    }
                }}
                renderInput={(params) => (
                    <TextField sx={{ width: { md: props?.width ? props?.width : "100%" }, fontSize: "16px" }} {...params} />
                )}
            />
            <FormHelperText error>{error}</FormHelperText>
        </Box>
    );
});

export default DefaultDateInput;
