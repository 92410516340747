import React, { useState, lazy, useEffect } from "react";
import Loader from "components/Loader";
import Routes from "routes";
import { SERVER_URL } from "config";
import { useLocation } from "react-router";
import Loadable from "components/Loadable";

import { useGetMenusQuery, useGetSiteInfoQuery } from "api/main/main";

import { ThemeProvider } from "@mui/material/styles";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import ScrollToTop from "utils/scrolltoTop";
import themes from "themes";
import "swiper/css";
import "swiper/css/pagination";
import "./assets/css/reset.css";
import "./assets/css/main.css";
import "./assets/css/common.css";
import "./assets/css/sub.css";
import "./assets/css/media.css";
import i18n from "i18n";
import LocaleContext from "LocaleContext";
import NotFound from "components/notFound";

const Header = Loadable(lazy(() => import("views/layout/header")));
const Footer = Loadable(lazy(() => import("views/layout/footer")));
const SubMenu = Loadable(lazy(() => import("views/layout/subMenu")));

function App() {
    const location = useLocation();
    const [locale, setLocale] = useState(i18n.language);
    const { data: siteInfo, loading } = useGetSiteInfoQuery();
    const { data: menuData, menuLoading } = useGetMenusQuery();

    useEffect(() => {
        if (siteInfo) {
            let favIcon = siteInfo?.content?.atchFav?.url;
            document.title = siteInfo?.content?.siteNm;

            if (favIcon) {
                let favLink = document.createElement("link");
                let head = document.getElementsByTagName("head")[0];
                favLink.id = "favicon";
                favLink.type = "image/x-icon";
                favLink.rel = "icon";
                favLink.href = SERVER_URL + favIcon;
                head.appendChild(favLink);
            }
        }
    }, [siteInfo]);

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <StyleSheetManager shouldForwardProp={isPropValid} disableVendorPrefixes={false}>
                <ThemeProvider theme={themes()}>
                    {(menuLoading || loading) && <Loader />}
                    <Header menuData={menuData} menuLoading={menuLoading} siteInfo={siteInfo?.content || null} />
                    {location.pathname != "/" && menuData ? <SubMenu menuData={menuData} location={location} /> : ""}
                    <ScrollToTop>
                        <Routes />
                    </ScrollToTop>
                    <Footer data={siteInfo?.content} />
                </ThemeProvider>
            </StyleSheetManager>
        </LocaleContext.Provider>
    );
}

export default App;
