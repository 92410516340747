import React from "react";

function Esg() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn esg sub_container">
                <h2 class="sub_custom_ttl">Vision</h2>
                <div class="vision_conts">
                    <div class="item">
                        <h3>
                            서울특별시 문화예술과 산하 비영리 법인 <br />
                            희망나무재단은
                        </h3>
                        <h3>
                            문화컨텐츠를 통해서 <br />
                            사람과 사람의 다리를 잇는 봉사 단체이며
                        </h3>
                        <h3>
                            [법인세법 시행령] 제 39조 제1항 11조에 따른 <br />
                            기획재정부 장관이 지정하는 공익법인입니다.
                        </h3>
                    </div>
                    <div class="item">
                        <h4>봉사를 실천하는 행복으로,</h4>
                        <article>
                            희망나무재단은 문화컨텐츠를 통해서 사람과 사람의 <br /> 다리를 잇는 [법인세법 시행령] 제 39조 제1항 11조에 따른
                            <br />
                            기획재정부 장관이 지정하는 공익법입니다.
                        </article>
                        <h4>인류의 삶을 풍요롭게 만드는,</h4>
                        <article>
                            희망나무재단은 문화교류와 봉사를 중심으로 활동하는 <br />
                            서울특별시 문화예술과 산하 비영리 법인입니다.
                        </article>
                        <h4>문화교류 비영리법인으로,</h4>
                        <article>
                            문화교류, 예술진시, 아카데미를 통하여 민간 문화교류를 <br /> 이끄는 비영리법인입니다.
                        </article>
                    </div>
                </div>
                <h2 class="sub_custom_ttl">Mission</h2>
                <div class="mission_conts">
                    <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="Mission" />
                    <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="Mission" />
                    <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="Mission" />
                    <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="Mission" />
                </div>
                <h2 class="sub_custom_ttl">Value</h2>
                <div class="value_conts">
                    <h4>사단법인 희망나무재단은 문화컨텐츠를 통해서 사람과 사람의 다리를 잇는 봉사 단체입니다.</h4>
                    <p>
                        문화교류를 통해서 봉사를 실천하고 봉사의 싸이클 안에서 문화를 체험하고 나눔을 실천하는 선순환의 영향력이 커지는 것을
                        목표로 하고 있습니다. <br />
                        <span>사단법인 희망나무재단은 문화교류와 봉사를 중심으로 활동하는 비영리 단체입니다.</span>
                    </p>
                    <h4>문화교류 아카데미 사업</h4>
                    <p>
                        1인가구의 사회적 고립문제를 해결하기 위하여 문화예술을 매개체로 교류할 수 있는 문화교류 아카데미 사업을 하고 있으며,
                        대한민국이 당면한 인구소멸의 문제까지 고려하여 1인가구들 중에서도 싱글만 입학할 수 있는 [마음:잇다] 아카데미와 서로
                        다른 지역의 청년들이 문화로교류할 수 있는 관계인구를 활성화를 위한 청년 프로젝트 사업에 앞장서고 있습니다.
                    </p>
                    <h4>문화 콘텐츠 사업</h4>
                    <h5 class="bottom_line">컬쳐스케치 원데이 클레스</h5>
                    <p>
                        지역의 관광 영소에서 청년들이 역사문화를 통하여 교류할 수 있도록 청년들의 명소 반짝번개와 모임을 통하여 1인 가구의
                        모임을 주도하고 있습니다. 또한, 지역의 관광명소를 청년에게 알릴 수 있는 핫플레이스 해시태그 캠페인을 진행하고
                        있으며, 관광 명소에 방문한 청년들이 직접 참여하여 제작한 영상을 통해서 관광명소를 200% 즐기는 방법을 다양한 콘텐츠로
                        제작하여 관광 명소를 알리는 유튜브 채널을 운영하고 있다.
                    </p>
                    <h4>공연 전시 나눔 지원사업</h4>
                    <p>공연과 전시 등 다양한 행사를 통하여 문화예술을 경험하게 하고, 소외계층에게 다양한 후원과 봉사를 하고 있습니다.</p>
                    <p>
                        <span>
                            문화교류를 통한 봉사와 나눔으로 사회에 작은 빛이 되어 점점 더 많은 빛을 모을 수 있게 노력하는 단체가 되겠습니다.
                        </span>
                        <br />본 사단법인은 문화 교류 예술 전시 아카데미를 통하여 민간 문화교류 봉사단체로써 일련의 노력들을 다할 것이며
                        따뜻하고 나눔의 가치를 실현하는 아름다운 세상과 사회를 만들기 위하여 소회빈민계층을 위한 문화교류, 나눔 지원 및
                        인간의 문화예술적 가치를 실현하는데 기여하고자 합니다.
                    </p>
                </div>
                <h2 class="sub_custom_ttl identity">Corporation Identity</h2>
                <div class="identity_conts">
                    <h4>희망나무재단 로고</h4>
                    <div class="inner">
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/Njx36g9G/logo.png" alt="희망나무재단 로고" />
                            </div>
                            <div class="logo_download">
                                <button class="btn_green">희망나무재단 CI(국문) 다운로드</button>
                            </div>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/Njx36g9G/logo.png" alt="희망나무재단 로고" />
                            </div>
                            <div class="logo_download">
                                <button class="btn_green">희망나무재단 CI(영문) 다운로드</button>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="sub_custom_ttl">History</h2>
                <h3 class="history_tit">현재 ~ 2020</h3>
            </div>
        </div>
    );
}

export default Esg;
