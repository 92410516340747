import React, { forwardRef, useEffect, useState } from "react";
import { Select as BaseSelect, FormControl, FormHelperText, MenuItem, OutlinedInput, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const DefaultSelect = forwardRef(
    (
        {
            seq,
            groupName,
            error,
            loading,
            disabled,
            label,
            defaultOption,
            all,
            helperText: helperTextProp,
            options,
            minWidth,
            dataType,
            width,
            moWidth,
            ...props
        },
        _
    ) => {
        const [helperTextData, setHelperText] = useState(false);
        const { t, i18n } = useTranslation();

        useEffect(() => {
            setHelperText(helperTextProp || "");
        }, [helperTextProp]);

        if (loading) return <Skeleton animation="wave" height={60} sx={{ borderRadius: "12px" }} />;

        return (
            <FormControl
                error={helperTextData ? true : false}
                size="small"
                sx={{
                    width: width || "200px",
                    alignItems: "flex-start",
                    "@media (max-width: 500px)": {
                        width: moWidth ? moWidth : "100%"
                    }
                }}
                disabled={disabled}
            >
                {label && <Typography>{label}</Typography>}
                {dataType !== "data" ? (
                    <BaseSelect
                        displayEmpty
                        input={<OutlinedInput />}
                        label={props.placeholder ?? ""}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200, borderColor: error ? "red" : "" } } }}
                        sx={{ width: "100%" }}
                        {...props}
                    >
                        {defaultOption === "select" ? <MenuItem value="">선택</MenuItem> : <MenuItem value="">{t("All")}</MenuItem>}
                        {options?.map((value, i) => (
                            <MenuItem key={i} value={seq ? value.seq : value.code}>
                                {i18n.language === "kr" ? value.codeNm : value.codeEngNm}
                            </MenuItem>
                        ))}
                    </BaseSelect>
                ) : (
                    <BaseSelect displayEmpty input={<OutlinedInput />} label={props.placeholder ?? ""} {...props}>
                        {defaultOption === "select" ? <MenuItem value="">선택</MenuItem> : <MenuItem value="">{t("All")}</MenuItem>}
                        {options?.map((value, i) => (
                            <MenuItem key={i} value={value.authorCd ? value.authorCd : ""}>
                                {value.authorNm || ""}
                            </MenuItem>
                        ))}
                    </BaseSelect>
                )}

                <FormHelperText error sx={{ ml: "0" }}>
                    {helperTextData || error}
                </FormHelperText>
            </FormControl>
        );
    }
);

export default DefaultSelect;
