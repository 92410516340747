import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSponsorCreateMutation } from "api/sponsor/index.js";
import { useGetSiteInfoQuery } from "api/main/main";
import Loader from "components/Loader";
import { Checkbox, Typography } from "@mui/material";
import { CheckBoxGroup, DatePicker, RadioGroup, Select, TextInput } from "components/useForm";
import { useGetCommonByGroupCodesQuery } from "api/commonCode";
import { CustomSubmitButton } from "components/buttons/CustomButton";
import { ConfirmModal } from "components/modal/confirmModal";
import AlertModal from "components/modal/AlertModal";
import { useNavigate } from "react-router";
import SignatureCanvas from "react-signature-canvas";
import { BasicModal } from "components/modal/basicModal";
import DaumPostcode from "react-daum-postcode";

const formDefaultValues = {
    name: "",
    email: "",
    phoneStart: "",
    phoneMiddle: "",
    phoneEnd: "",
    birthdayDate: null,
    address: "",
    zip: "",
    addressEtc: "",
    schoolName: "",
    job: "",
    marriedYn: "N",
    reasonJoining: "",
    division: "SP_01",
    memberFee: "MF_01",
    memberFeeOth: "",
    type: "ST_01",
    method: "MT_01",
    registrationNumber: "",
    registrationNumberEnd: "",
    bank: "",
    bankHolder: "",
    bankAccount: "",
    transferDate: "TD_01",
    transferDateOth: "",
    monthFee: "EM_01",
    monthFeeOth: "",
    mobileStart: "",
    mobileMiddle: "",
    mobileEnd: ""
};

const Sponsor = () => {
    const navigate = useNavigate();
    const { data: siteInfo, loading } = useGetSiteInfoQuery();
    const [onAction, resultAction] = useSponsorCreateMutation();
    const [checkRequiredItems, setCheckRequiredItems] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState("");
    const [termChecked, setTermChecked] = useState(false);
    const [termShow, setTermShow] = useState(false);
    const { data: groupCode, isLoading: groupIsLoading } = useGetCommonByGroupCodesQuery([
        "MARRIED_TYPE",
        "SPONSOR_TYPE",
        "SPONSOR_DIVISION",
        "METHOD_TYPE",
        "BANK_TYPE",
        "EVERY_MONTH",
        "TRANSFER_DATE",
        "MEMBER_FEE",
        "PHONE_TYPE"
    ]);
    const canvasRef = useRef();
    const [isSigned, setIsSigned] = useState(false);

    const {
        watch,
        handleSubmit,
        control,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onBlur",
        defaultValues: formDefaultValues
    });

    const dataURLtoFile = (dataURL, fileName) => {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };

    const convertDataUrlToFile = (name) => {
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL("image/png");
        const file = dataURLtoFile(dataURL, `${name}.png`);
        return file;
    };

    const submit = useCallback(
        (values) => {
            const newData = {
                ...values
            };

            let formData = new FormData();
            Object.keys(newData).forEach((key) => {
                formData.append(key, newData[key]);
            });

            formData.append("phone", watch("phoneStart") + "-" + watch("phoneMiddle") + "-" + watch("phoneEnd"));
            formData.append("mobile", watch("mobileStart") + "-" + watch("mobileMiddle") + "-" + watch("mobileEnd"));
            const signatureFile = convertDataUrlToFile("signature");
            formData.append("signature", signatureFile);

            onAction(formData)
                .then((res) => {
                    setShowConfirmModal(false);
                    if (res.data.status === 200) {
                        setShowAlertModal(true);
                        setAlertModalContent("후원해주셔서 감사합니다.");
                    } else {
                        setAlertModalContent("관리자 연락하시기 바랍니다.");
                    }
                })
                .then(() => {
                    navigate("/complete");
                })
                .catch((err) => console.log("useSponsorCreateMutation ERROR => ", err));
        },
        [onAction, watch]
    );

    const handleAddressComplete = (data) => {
        const fullAddress = data.address;
        const zonecode = data.zonecode;
        setValue("zip", zonecode);
        setValue("address", fullAddress);
        setAddressModal(false);
    };

    const handleButtonClick = handleSubmit((values) => {
        const showAlert = (content) => {
            setShowAlertModal(true);
            setAlertModalContent(content);
        };

        if (values.name.trim() === "") {
            showAlert("이름을 입력하세요.");
        } else if (values.birthdayDate === null) {
            showAlert("생년월일을 입력하세요.");
        } else if (values.address.trim() === "") {
            showAlert("주소를 입력하세요.");
        } else if (values.addressEtc.trim() === "") {
            showAlert("상세 주소를 입력하세요.");
        } else if (values.email.trim() === "") {
            showAlert("이메일을 입력하세요.");
        } else if (values.phoneStart.trim() === "" || values.phoneMiddle.trim() === "" || values.phoneEnd.trim() === "") {
            showAlert("연락처를 입력하세요.");
        } else if (values.division.trim() === "") {
            showAlert("회원구분을 선택하세요.");
        } else if (!isSigned) {
            showAlert("출금동의 인증하세요.");
        } else if (!termChecked) {
            showAlert("회원가입 약관을 동의해주세요.");
        } else if (watch("method") === "MT_02") {
            if (values.registrationNumber.trim() === "" || values.registrationNumberEnd.trim() === "") {
                showAlert("주민등록번호를 입력하세요.");
            } else if (values.bank.trim() === "") {
                showAlert("은행명을 선택하세요.");
            } else if (values.bankAccount.trim() === "") {
                showAlert("계좌번호를 입력하세요.");
            } else if (watch("transferDate") === "TD_03" && values.transferDateOth.trim() === "") {
                showAlert("이체일 기타 일을 입력하세요.");
            } else if (watch("monthFee") === "EM_06" && values.monthFeeOth.trim() === "") {
                showAlert("매월 기타 금액을 입력하세요.");
            } else {
                setCheckRequiredItems(true);
                setShowConfirmModal(true);
            }
        } else {
            setCheckRequiredItems(true);
            setShowConfirmModal(true);
        }
    });

    const handleAlertModalClose = () => {
        setShowAlertModal(false);
    };

    return (
        <div className="sub_container">
            {resultAction.isLoading && <Loader />}

            <h2 className="not_banner_tit">
                <div>기획재정부지정 공익법인 희망나무재단</div>회 원 가 입 신 청 서
            </h2>
            <form onSubmit={handleSubmit(submit)}>
                <h4 className="table_req_text">
                    <span className="reqItem">*</span> 필수 입력 항목입니다.
                </h4>
                <div className="form_wrap">
                    <div className="item item_two">
                        <div className="item_inn">
                            <h4>
                                이름 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field">
                                <TextInput control={control} name="name" maxLength="30" placeholder="이름을 입력하세요." type="text" />
                            </div>
                        </div>
                        <div className="item_inn">
                            <h4>
                                생년월일 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field">
                                <DatePicker width={250} control={control} name="birthdayDate" />
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item_inn">
                            <h4>
                                주소 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field address_from">
                                <div className="zip_search">
                                    <TextInput control={control} name="zip" placeholder="우편번호" disabled width="100px" />
                                    <CustomSubmitButton onClick={() => setAddressModal(true)} text={"우편번호찾기"} size={"sm"} />
                                </div>
                                <TextInput control={control} name="address" placeholder="주소" disabled />
                                <TextInput control={control} name="addressEtc" placeholder="상세 주소를 입력하세요" />
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item_inn">
                            <h4>
                                이메일 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field">
                                <TextInput control={control} name="email" maxLength="100" placeholder="이메일을 입력하세요" />
                            </div>
                        </div>
                    </div>
                    <div className="item item_two">
                        <div className="item_inn">
                            <h4>
                                연락처 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field">
                                <Select
                                    control={control}
                                    options={(!groupIsLoading && groupCode?.PHONE_TYPE) || []}
                                    name="phoneStart"
                                    defaultOption={"select"}
                                    width="100px"
                                    moWidth="80px"
                                />
                                <TextInput control={control} name="phoneMiddle" maxLength="4" type="number" width="100px" moWidth="60px" />
                                <TextInput control={control} name="phoneEnd" maxLength="4" type="number" width="100px" moWidth="60px" />
                            </div>
                        </div>
                        <div className="item_inn">
                            <h4>휴대폰</h4>
                            <div className="form_field">
                                <Select
                                    control={control}
                                    options={(!groupIsLoading && groupCode?.PHONE_TYPE) || []}
                                    name="mobileStart"
                                    defaultOption={"select"}
                                    width="100px"
                                    moWidth="80px"
                                />
                                <TextInput control={control} name="mobileMiddle" maxLength="4" type="number" width="100px" moWidth="60px" />
                                <TextInput control={control} name="mobileEnd" maxLength="4" type="number" width="100px" moWidth="60px" />
                            </div>
                        </div>
                    </div>
                    <div className="item item_two">
                        <div className="item_inn">
                            <h4>학생</h4>
                            <div className="form_field">
                                <TextInput control={control} name="schoolName" maxLength="100" placeholder="학교명을 입력하세요" />
                                <Typography sx={{ whiteSpace: "nowrap" }}>학교</Typography>
                            </div>
                        </div>
                        <div className="item_inn">
                            <h4>직장</h4>
                            <div className="form_field">
                                <TextInput control={control} name="job" maxLength="100" placeholder="직장명을 입력하세요" />
                            </div>
                        </div>
                    </div>
                    <div className="item item_two">
                        <div className="item_inn">
                            <h4>가입동기</h4>
                            <div className="form_field">
                                <TextInput control={control} name="reasonJoining" maxLength="100" placeholder="가입동기를 입력하세요" />
                            </div>
                        </div>
                        <div className="item_inn">
                            <h4>결혼사항</h4>
                            <div className="form_field">
                                <RadioGroup control={control} data={!groupIsLoading && groupCode?.MARRIED_TYPE} name="marriedYn" />
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item_inn">
                            <h4>
                                회원구분 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field">
                                <CheckBoxGroup
                                    control={control}
                                    name="division"
                                    setValue={setValue}
                                    options={!groupIsLoading && groupCode?.SPONSOR_DIVISION}
                                />
                                <Typography sx={{ whiteSpace: "nowrap" }}>( 중복가능 )</Typography>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item_inn">
                            <h4>
                                회원회비 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field">
                                <RadioGroup control={control} data={!groupIsLoading && groupCode?.MEMBER_FEE} name="memberFee" />
                                {watch("memberFee") === "MF_05" && (
                                    <>
                                        <TextInput
                                            control={control}
                                            name="memberFeeOth"
                                            maxLength="100"
                                            width="100px"
                                            type="money"
                                            sx={{ textAlign: "right" }}
                                            placeholder="0"
                                        />
                                        만원
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item_inn">
                            <h4>
                                후원형태 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field">
                                <RadioGroup control={control} data={!groupIsLoading && groupCode?.SPONSOR_TYPE} name="type" />
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item_inn">
                            <h4>
                                후원방법 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field method_bank_info">
                                <RadioGroup
                                    control={control}
                                    data={!groupIsLoading && groupCode?.METHOD_TYPE}
                                    name="method"
                                    style={{ flexDirection: "column" }}
                                />
                                <div className="bank_info">
                                    <h5 style={{ whiteSpace: "nowrap" }}>후원계좌 :</h5>
                                    <p>
                                        {siteInfo?.content?.bank} {siteInfo?.content?.accountNumber} {siteInfo?.content?.accountHolder}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item_inn">
                            <h4>
                                출금동의 인증 <span className="reqItem">*</span>
                            </h4>
                            <div className="form_field form_sign_area">
                                <div className="guide_re">
                                    <p>* 마우스를 올린 후 클릭한 상태에서 서명해주세요</p>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            canvasRef.current.clear();
                                            setIsSigned(false);
                                        }}
                                    >
                                        다시 서명하기
                                    </button>
                                </div>
                                <div className="sign_area">
                                    {!isSigned && <div className="sign_placeholder">여기에 서명을 해주세요</div>}
                                    <SignatureCanvas
                                        canvasProps={{
                                            className: "signature-canvas"
                                        }}
                                        ref={canvasRef}
                                        onBegin={() => {
                                            setIsSigned(true);
                                        }}
                                        clearOnResize={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CMS(자동이체) */}
                    {watch("method") === "MT_02" && (
                        <>
                            <Typography variant="h2" sx={{ marginY: "40px", textAlign: "center", display: "block", width: "100%" }}>
                                ♧ CMS 후원신청 ♧
                            </Typography>
                            <div className="cms_into">
                                <p>
                                    ◈ 금융결제원의 협조로 본인의 은행명, 계좌번호, 주민등록번호 등을 자세히 알려주시면 매달 약정하신 금액이
                                    인출됩니다.
                                </p>
                                <p>◈ 연말정산시 기부금영수증을 발급해 세금공제 혜택을 100% 받으실 수 있습니다.</p>
                            </div>

                            <div className="item" style={{ borderTop: "1px solid #e0e0e0" }}>
                                <div className="item_inn">
                                    <h4>
                                        주민등록번호 <span className="reqItem">*</span>
                                    </h4>
                                    <div className="form_field">
                                        <TextInput
                                            control={control}
                                            name="registrationNumber"
                                            maxLength="6"
                                            width="100px"
                                            type="number"
                                            placeholder="앞 6자리"
                                        />
                                        <Typography>-</Typography>
                                        <TextInput
                                            control={control}
                                            name="registrationNumberEnd"
                                            maxLength="7"
                                            width="100px"
                                            type="number"
                                            placeholder="뒤 7자리"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item_inn">
                                    <h4>
                                        계좌정보 <span className="reqItem">*</span>
                                    </h4>
                                    <div className="form_field">
                                        <Select
                                            control={control}
                                            options={(!groupIsLoading && groupCode?.BANK_TYPE) || []}
                                            name="bank"
                                            defaultOption={"select"}
                                            label="은행명"
                                        />
                                        <TextInput control={control} name="name" label="성명" disabled width="200px" />
                                        <TextInput
                                            control={control}
                                            name="bankAccount"
                                            label="계좌번호"
                                            maxLength="16"
                                            type="number"
                                            placeholder="- 없이 입력하세요."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item_inn">
                                    <h4>
                                        이체일 <span className="reqItem">*</span>
                                    </h4>
                                    <div className="form_field">
                                        <RadioGroup
                                            control={control}
                                            data={!groupIsLoading && groupCode?.TRANSFER_DATE}
                                            name="transferDate"
                                        />
                                        {watch("transferDate") === "TD_03" && (
                                            <>
                                                <TextInput
                                                    control={control}
                                                    name="transferDateOth"
                                                    maxLength="2"
                                                    width="50px"
                                                    type="number"
                                                    placeholder="0"
                                                />
                                                일
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item_inn">
                                    <h4>
                                        매월 <span className="reqItem">*</span>
                                    </h4>
                                    <div className="form_field">
                                        <RadioGroup control={control} data={!groupIsLoading && groupCode?.EVERY_MONTH} name="monthFee" />
                                        {watch("monthFee") === "EM_06" && (
                                            <>
                                                <TextInput
                                                    control={control}
                                                    name="monthFeeOth"
                                                    maxLength="10"
                                                    width="100px"
                                                    type={"money"}
                                                    placeholder="0"
                                                />
                                                만원
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <p style={{ fontSize: "30px", fontWeight: "bold", width: "100%", marginBottom: "20px", marginTop: "30px" }}>
                        가입약관 동의
                    </p>
                    <div className="term_inn">
                        <div className="keep_check">
                            <Checkbox
                                name="termChecked"
                                onChange={(e) => {
                                    setTermChecked(e.target.checked);
                                }}
                                id="term"
                            />
                            <label htmlFor="term" className="keep_text">
                                이용약관에 동의합니다.
                            </label>
                        </div>
                        <button
                            type="button"
                            style={{ padding: "2px 10px" }}
                            className="custom_button"
                            onClick={() => setTermShow((prevState) => !prevState)}
                        >
                            보기
                        </button>
                    </div>
                    {termShow && (
                        <div className="term_content">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: siteInfo?.content?.privacyPolicy
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="join_want">본인은 희망나무재단의 회원으로 가입하기를 원합니다.</div>
                <div className="btn_wrap" style={{ justifyContent: "center" }}>
                    <CustomSubmitButton onClick={handleButtonClick} text={"후원하기"} />
                </div>
                {checkRequiredItems && (
                    <ConfirmModal
                        open={showConfirmModal}
                        close={() => setShowConfirmModal(false)}
                        onClick={handleSubmit(submit)}
                        title="안내"
                        text={"후원하시겠습니까?"}
                        loading={resultAction?.isLoading}
                        disabled={resultAction?.isLoading}
                    />
                )}

                <AlertModal
                    open={showAlertModal}
                    close={() => setShowAlertModal(false)}
                    onClick={handleAlertModalClose}
                    title={"안내"}
                    text={alertModalContent}
                    loading={resultAction?.isLoading}
                    disabled={resultAction?.isLoading}
                />

                {/* address */}
                <BasicModal open={addressModal} close={() => setAddressModal(false)} width={{ xs: "95%", md: 800 }}>
                    <DaumPostcode onComplete={handleAddressComplete} autoClose style={{ height: "445px" }} />
                </BasicModal>
            </form>
        </div>
    );
};

export default Sponsor;
