import { useTranslation } from "react-i18next";

export default function Info() {
    const { t } = useTranslation();

    return (
        <div className="solution_footer">
            <h5>{t("guide_text")}</h5>
        </div>
    );
}
