/**
 * COMMON FUNCTIONS
 */
import receipt from "assets/imgs/icons/1._receipt.png";
import estimate from "assets/imgs/icons/2._estimate.png";
import contract from "assets/imgs/icons/3._contract.png";
import progressing from "assets/imgs/icons/4._progressing.png";
import complete from "assets/imgs/icons/5._complete.png";

var loggedInUser = "";
export var userToken = ""; //  token for logged in user

//  setting loggedin user
export function setLoggedInUser(userData) {
    loggedInUser = userData;
}

//  setting userToken
export function setUserToken(newToken) {
    userToken = newToken;
}

export function getLoggedInUser() {
    return loggedInUser;
}

//  getting translated text
export function getTranslatedText(text) {
    return text;
}

export function getCurrentUrl() {
    return window.location.pathname;
}

//@author: Dral
//get corresponding name
export function findCorName(id, column, list, corr) {
    if (list && list.length > 0) {
        const item = list.find((item) => {
            if (corr) {
                return id == item[corr];
            }
            return id == item.code;
        });
        return item ? item[column] : "no item";
    }
    return "";
}

//@author: Dral
//get calculated seq
export const calcSeq = (data, index) => {
    if (data?.totalElements) {
        return +data?.totalElements - +data?.number - index;
    }
    return 0;
};

export function addComma(n) {
    var reg = /(^[+-]?\d+)(\d{3})/; // 정규식
    n += ""; // 숫자를 문자열로 변환

    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

    return n;
}

export function removeComma(str) {
    var reg = /[,]/g;
    var val = str.replace(reg, "");

    if (isNaN(val)) return str;
    else return parseInt(val, 10);
}

export function getProjectStatusName(statusCd) {
    const statusCodes = {
        R: "접수", // Registered
        G: "견적중", // Estimating
        E: "견적", // Estimated
        C: "계약", // Contract
        I: "진행중", // In progress
        D: "개발완료", // Development complete
        P: "결제완료" // Payment complete
    };
    return statusCodes[statusCd] ? `${statusCodes[statusCd]}` : " - ";
}
export function getProjectIcon(statusCd) {
    const statusCodes = {
        R: receipt, // Registered
        G: estimate, // Estimating
        E: estimate, // Estimated
        C: contract, // Contract
        I: "", // In progress
        D: complete, // Development complete
        P: complete // Payment complete
    };
    return statusCodes[statusCd] ? `${statusCodes[statusCd]}` : " - ";
}

export function getProjectStatusList() {
    let statusList = [
        { code: "R", description: "접수" },
        { code: "G", description: "견적중" },
        { code: "E", description: "견적" },
        { code: "C", description: "계약" },
        { code: "I", description: "진행중" },
        { code: "D", description: "개발완료" },
        { code: "P", description: "결제완료" }
    ];
    return statusList;
}

export function getProjectStatusProgress(statusCd) {
    const statusPercents = {
        R: "",
        G: "10%",
        E: "20%",
        C: "30%",
        I: "60%",
        D: "90%",
        P: "100%"
    };
    return statusPercents[statusCd] ? `${statusPercents[statusCd]}` : " - ";
}
