export const URL = {
    LOGIN: "/web/login",
    MENU_LIST: "/web/menu/list",
    SITE_INFO: "/web/site/info",
    USER_INFO: "/web/user/info",
    USER_UPDATE: "/web/user/update",
    USER_CREATE: "/web/user/create",
    USER_NAME_CHECK: "/web/user/userIdExist",
    EMAIL_VERIFICATION_SEND: "/web/email/verification/send",
    EMAIL_VERIFY: "/web/email/auth/verify",
    FORGOT_ID: "/web/forgot/id",
    FORGOT_PASSWORD: "/web/forgot/password",

    PROJECT_LIST: "/web/project/list",
    PROJECT_DETAIL: "/web/project",
    PROJECT_CREATE: "/web/project/create",
    PROJECT_UPDATE: "/web/project/update",
    PROJECT_UPDATE_CONTRACT_STATUS: "/web/project/update/contract",
    PROJECT_PAYMENT_LIST: "/web/project/payment/list",
    PROJECT_PAYMENT_DETAIL: "/web/project/payment/detail",

    COMMONCODE_FILTER_LIST: "/web/codes/group",
    COMMONCODE_FILTER_BY_GROUP_LIST: "/web/codes/group/list",

    // NOTICE
    NOTICE_LIST: "/web/notice/list",
    NOTICE_DETAIL: "/web/notice/detail",

    QNA_LIST: "/web/qna/list",
    QNA_DETAIL: "/web/qna/detail",
    QNA_CREATE: "/web/qna/create",
    QNA_UPDATE: "/web/qna/update",
    QNA_DELETE: "/web/qna/delete",

    CONTACT: "/web/contact/create",
    CONTACT_LIST: "/web/contact/list",
    CONTACT_DETAIL: "/web/contact/detail",

    INQUIRY_CREATE: "/web/inquiry/create",

    GALLERY_LIST: "/web/gallery/list",
    GALLERY_DETAIL: "/web/gallery/detail",

    FAQ_LIST: "/web/faq/list",
    DYNAMIC_PAGE: "/web/menu/contents",

    REVIEW_LIST: "/web/review/list",
    REVIEW_DETAIL: "/web/review/detail",
    REVIEW_CREATE: "/web/review/create",

    STAFF_LIST: "/web/staff/list",
    STAFF_CEO: "/web/staff/detail/ceo",
    STAFF_CTO: "/web/staff/detail/cto",
    STAFF_VICE: "/web/staff/detail/president",
    STAFF_VICE_TWO: "/web/staff/detail/presidenttwo",
    STAFF_DETAIL: "/web/staff/detail",
    STAFF_CREATE: "/web/staff/create",

    CERT_LIST: "/web/certificate/list",

    // NOTICE
    NEWS_LIST: "/web/news/list",
    NEWS_DETAIL: "/web/news/detail",

    PORTFOLIO_LIST: "/web/portfolio/list",
    YOUTUBE_LIST: "/web/youtube/list",

    SOLUTION_SMS: "/web/solution/detail/sms",
    SOLUTION_KAKAO: "/web/solution/detail/kakao",
    SOLUTION_RCS: "/web/solution/detail/rcs",
    SOLUTION_OUTSOURCING: "/web/solution/detail/outsourcing",

    SPONSOR_CREATE: "/web/sponsor/create",

    //MAIN
    MAIN_INFO: "/web/main",
    CURRENT_TIME: "/web/current/time"

    //BANNER
    // BANNER_LIST: '/web/main'
};
