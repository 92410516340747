import { Modal, Typography } from "@mui/material";
import { ModalInner } from "./ModalInner";
import { LoadingButton } from "@mui/lab";
import ButtonWrapp from "components/buttons/ButtonWrapp";

const AlertModal = ({ open, close, text, title }) => {
    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner>
                <Typography variant="h4" sx={{ borderBottom: "1px solid #b7b7b7", paddingBottom: "10px" }}>
                    {title}
                </Typography>
                <Typography id="parent-modal-description" variant="h4" sx={{ textAlign: "center", margin: "30px 0" }}>
                    {text}
                </Typography>
                <ButtonWrapp sx={{ justifyContent: "center" }}>
                    <LoadingButton variant="contained" autoFocus onClick={close} sx={{ padding: "5px 30px" }}>
                        확인
                    </LoadingButton>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
};

export default AlertModal;
