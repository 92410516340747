import React from "react";

function Greeting() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn greeting sub_container">
                <h2 class="sub_custom_ttl">인사말</h2>
                <h4>"희망나무재단을 방문해주신 여러분을 진심으로 환영합니다”</h4>
                <article>
                    안녕하세요? <br /> 희망나무재단 이사장 이원제입니다.
                    <br /> 희망나무재단은
                    <strong>
                        서울시 문화예술과 산하 비영리법인이며, <br />
                        [법인세법시행령] 제 39조 제1항 11호에 따른 기획재정부장관이 지정하는 공익법인
                    </strong>
                    입니다.
                </article>
                <article>
                    희망나무재단에서는 문화, 예술, 역사, 인문, 심리 등을 통하여 사람과 사람 사이를 이어주고 <br />
                    삶의 기쁨과 행복을 누릴 수 있도록 봉사하는 단체입니다.
                    <br /> 사람을 섬기고 존중하고 봉사하는 일은 누구나 할 수 있고 어떤 방식으로든 할 수 있지만, <br />
                    문화를 통한 마음의 풍요와 기쁨을 통한 봉사와 기부활동을 하면서 많은 사람들과 <br />
                    마음의 평화를 나누기 위해서 희망나무재단을 시작하게 되었습니다.
                </article>
                <article>
                    사회적, 경제적 여건 및 가치관의 변화로 국내 1인 가구의 수는 해를 거듭할수록 증가하고 있습니다.
                    <br /> 2022년 발표된 통계청의 자료에 따르면 전체 1인가구의 비율은 33.4%에 이르며 해마다 <br />
                    홀로 살아가는 사람들이 점점 늘어나고 있습니다.
                    <br /> 홀로 살아가는 1인 가구는 혼자 생활하고 혼밥을 하면서 영양 불균형의 문제를 일으킬 수 있으며,
                    <br /> 사회적 고립으로 우울증과 무기력의 문제와 같이 심리적 문제뿐만 아니라 <br />
                    갑자기 건강의 문제 등이 발생하게 되었을 때 고독사로 이어질 수 있는 안전의 문제로까지 이어질 수 있습니다.
                    <br /> 이에 희망나무재단은 1인가구의 문화교류를 통하여 삶의 질과 심리적, 신체적 안전과 사회 안전망을 <br />
                    개선하기 위하여 다양한 문화교류 활동을 하고 있습니다.
                </article>
                <article>
                    물질적인 가난보다 마음과 정신의 가난이 더 사람을 가난하게 만듭니다. <br />
                    세상에 물질적인 가난, 신체적인 부족함을 갖고 살아가는 사람들이 많습니다. <br />
                    너무나 많은 다양한 사연들로 인해서 정신적인 가난을 갖고 살아가는 사람들이 많이 있습니다.
                    <br /> 물질적인 풍요를 갖고 있지만 정신적인 가난을 갖고 있는 사람에게는 문화를 매개체로 봉사를 하는 기쁨을 주고,
                    <br />
                    물질적인 가난을 갖고 있는 사람에게는 봉사와 기부로 도우면서 동시에 정신적인 풍요를 느낄 수 있게 도와주고 있습니다.
                    <br />
                    문화컨텐츠를 매개체로 진실로 기쁨을 주고 세상을 풍요롭게 만들 수 있는 일을 하는 일에 <br />
                    많은 사람이 동참할 수 있게 희망나무재단에서 돕겠습니다.
                    <br />
                    <br />
                    기쁨을 함께 하세요.
                    <br />
                    <br />
                    감사합니다.
                </article>
                <span>이사장 이원제</span>
            </div>
        </div>
    );
}

export default Greeting;
