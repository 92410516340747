import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { useGalleryListQuery } from "api/community/community";
import Loader from "components/Loader";
import { SERVER_URL } from "config";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function MnGallery() {
    const currentLanguage = i18next.language;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [options, setOptions] = useState({ page: 0, size: 12, searchWrd: "" });

    const { data, isLoading, isFetching, refetch } = useGalleryListQuery(new URLSearchParams({ ...options }).toString());

    useEffect(() => {
        refetch();
    }, []);

    const handlePageChange = (page) => setOptions((prevState) => ({ ...prevState, page: page - 1 }));
    const handleFirstPageChange = () => setOptions((prevState) => ({ ...prevState, page: 0 }));
    const handleLastPageChange = () => setOptions((prevState) => ({ ...prevState, page: data?.totalPages - 1 }));
    const handlePrevPageChange = () => setOptions((prevState) => ({ ...prevState, page: options.page > 0 ? options.page - 1 : 0 }));
    const handleNextPageChange = () =>
        setOptions((prevState) => ({ ...prevState, page: options.page + 1 != data?.totalPages ? options.page + 1 : data?.totalPages - 1 }));
    const numbers = Array.from({ length: data?.totalPages }, (_, index) => index + 1);

    const numberOfPages = numbers.map((number) => (
        <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={"paging_number " + (number == options.page + 1 ? "pag_active" : "")}
        >
            {number}
        </button>
    ));
    const handleDetailNav = (data) => {
        navigate("/mn/outsourcing/gallery/" + data);
    };

    return (
        <div className="conts sub-container">
            {(isLoading || isFetching) && <Loader />}
            <div className="cont_wrap gallery_wrap sub_conts_inn">
                <div className="gallery">
                    {data &&
                        data?.content.map((item, i) => (
                            <div className="item" key={i} onClick={() => handleDetailNav(item.seq)}>
                                <img src={SERVER_URL + item.attachments[0].url} alt="gallery" />
                                <div className="txt_wrap">
                                    <p>{currentLanguage === "kr" || !item.titleEn ? item.title : item.titleEn}</p>
                                    <span>
                                        {(item.regDate != "" && item.regDate != null
                                            ? item.regDate
                                            : item.createdDate.split(" ")[0]
                                        ).replaceAll("-", ".")}
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="paging_wrap">
                    <div className="paging_conts">
                        <button onClick={() => handleFirstPageChange()} className="paging_start"></button>
                        <button onClick={() => handlePrevPageChange()} className="paging_prev"></button>
                        {numberOfPages}
                        <button onClick={() => handleNextPageChange()} className="paging_next"></button>
                        <button onClick={() => handleLastPageChange()} className="paging_end"></button>
                    </div>
                </div>
            </div>
        </div>
    );
}
