import React from "react";

function Pub_about() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn sponsor">
                <h2 class="sub_conts_ttl">
                    희망나무재단은 1인 가구의 사회적 고립문제를 해결하기 위해 <br class="no_mo" /> 문화예술을 매개체로 교류할 수 있는
                    아카데미 사업을 지원하고 있습니다.
                </h2>
                <section class="strength sub_container">
                    <h3 class="sub_sec_ttl">희망나무재단의 강점</h3>
                    <div class="strength_cont">
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/nVYFgbC6/strength01.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>국내외 협력기관 구축</h4>
                                <p>
                                    국내 2,803개소, 해외 13개국의
                                    <br /> 국가와 협력관계를 구축하여 <br />
                                    소외된 이웃들을 위한 활동을
                                    <br /> 수행하고 있습니다.
                                </p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/WpXR02Db/strength02.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>투명한 NGO</h4>
                                <p>
                                    외부 회계법인으로부터 <br />
                                    정기적 감사를 통해 기부금의
                                    <br /> 사용처를 투명하게
                                    <br /> 공개하고 있습니다.
                                </p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/vHVzbhdF/strength03.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>UN 특별 협의적 지위 취득</h4>
                                <p>
                                    UN 특별협의적 지위를 취득한
                                    <br /> 기관으로 전 세계 사회문제 해결을 위해
                                    <br /> 투명성과 책무성을 기반으로 한<br /> 전문성을 갖춘 기관입니다.
                                </p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/YS98KHr8/strength04.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>맞춤형 사회공헌 사업 기획</h4>
                                <p>
                                    기업의 가치와 방향에 <br /> 맞는 사회공헌 컨설팅을
                                    <br /> 통해 맞춤형 사회공헌 사업을
                                    <br /> 기획합니다.
                                </p>
                            </article>
                        </div>
                    </div>
                </section>
                <section class="way">
                    <h3 class="sub_sec_ttl">기업사회공헌 참여 방법</h3>
                    <div class="way_conts sub_container">
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>기금후원</h4>
                                <p>기업이 희망하는 사업을 선택하여 사회공헌 성금을 기부합니다.</p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>캠페인 후원</h4>
                                <p>
                                    ESG경영에 발맞추어 기업의 가치 실현과 사회문제를 해결하는 캠페인에 참여합니다. <br /> 기업의
                                    임직원분들이 캠페인에 함께 참여합니다. EX) 어스앤어스, 기부런캠페인 등
                                </p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>공익연계마케팅(CRM)</h4>
                                <p>고객 참여형 캠페인으로써 고객이 기업의 제품을 구매하면 일정수익금을 기부합니다.</p>
                            </article>
                        </div>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/k4sDKBRv/Kakao-Talk-20221024-111156421-01.jpg" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>물품후원</h4>
                                <p>자원재순환 사업 및 취약계층 지원을 위해 기업의 판매, 재고상품을 기부합니다.</p>
                            </article>
                        </div>
                    </div>
                </section>
                <section class="eff sub_container">
                    <h3 class="sub_sec_ttl">희망나무재단의 강점</h3>
                    <div class="eff_cont">
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/XvzBzZYN/eff01.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>ESG 경영실천</h4>
                                <p>
                                    기업의 사회적 책임을 다하여
                                    <br /> 지역의 소외된 이웃을 지원하여
                                    <br /> ESG경영을 실천합니다.
                                </p>
                            </article>
                        </div>
                        <span>line</span>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/T18LTZbZ/eff02.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>취약계층 지원</h4>
                                <p>
                                    취약계층에게 가장 필요한 부분을
                                    <br /> 파악하고 지원합니다.
                                </p>
                            </article>
                        </div>
                        <span>line</span>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/BnZ8Z58Q/eff03.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>세제혜택</h4>
                                <p>
                                    지정기부금 단체로 기부금
                                    <br /> 영수증을 발행해 드리고 있으며,
                                    <br /> 세제혜택을 받을 수 있습니다.
                                </p>
                            </article>
                        </div>
                        <span>line</span>
                        <div class="item">
                            <div class="img">
                                <img src="https://i.postimg.cc/mkNDBhBH/eff04.png" alt="희망나무재단" />
                            </div>
                            <article>
                                <h4>기업홍보</h4>
                                <p>
                                    기업나눔스토리, 언론보도 등 <br /> 기업홍보를 통한 <br />
                                    이미지 제고가 가능합니다.
                                </p>
                            </article>
                        </div>
                    </div>
                </section>
                <section class="procedure">
                    <h3 class="sub_sec_ttl">기업사회공헌 참여 방법</h3>
                    <div class="procedure_conts sub_container">
                        <div class="item">
                            <h4>
                                <span>01.</span>문의 및 상담
                            </h4>
                            <p>
                                사회공헌 사업 활동과 관련한 <br /> 기업의 욕구 파악 및 상담
                            </p>
                            <img src="https://i.postimg.cc/XJVRPgQd/procedure01.png" alt="희망나무재단" />
                        </div>
                        <div class="item">
                            <h4>
                                <span>02.</span>사회공헌 기획 및 제안
                            </h4>
                            <p>
                                상담 내용을 토대로 <br />
                                사회공헌 사업 기획 및 제안
                            </p>
                            <img src="https://i.postimg.cc/Fs22vvT9/procedure02.png" alt="희망나무재단" />
                        </div>
                        <div class="item">
                            <h4>
                                <span>03.</span>파트너쉽 체결
                            </h4>
                            <p>
                                사회공헌 사업을 확정하고 <br />
                                파트너쉽을 체결
                            </p>
                            <img src="https://i.postimg.cc/FzYBtCcd/procedure03.png" alt="희망나무재단" />
                        </div>
                        <div class="item">
                            <h4>
                                <span>04.</span>기부금 영수증 발행
                            </h4>
                            <p>
                                상담 내용을 토대로
                                <br />
                                사회공헌 사업 기획 및 제안
                            </p>
                            <img src="https://i.postimg.cc/RFDgT18M/procedure04.png" alt="희망나무재단" />
                        </div>
                        <div class="item">
                            <h4>
                                <span>05.</span>사업수행 및 모니터링
                            </h4>
                            <p>
                                상담 내용을 토대로 <br />
                                사회공헌 사업 기획 및 제안
                            </p>
                            <img src="https://i.postimg.cc/SQwhScsF/procedure05.png" alt="희망나무재단" />
                        </div>
                        <div class="item">
                            <h4>
                                <span>06.</span>사업 결과보고
                            </h4>
                            <p>
                                사회공헌 사업을 확정하고 <br />
                                파트너쉽을 체결
                            </p>
                            <img src="https://i.postimg.cc/xCcwYdV1/procedure06.png" alt="희망나무재단" />
                        </div>
                    </div>
                </section>
                <section class="bggray"></section>
            </div>
        </div>
    );
}

export default Pub_about;
