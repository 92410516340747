import { MainConfig, CommunityConfig } from "views/pages";
import { CommonCodeFilterApi } from "api/commonCode";
import { BannerApi } from "api/main/banner";
import { SolutionApi } from "api/solution";
import { SponsorApi } from "api/sponsor";

// ==============================|| COMBINE REDUCER ||============================== //

const Reducer = {
    [MainConfig.API.reducerPath]: MainConfig.API.reducer,
    [CommunityConfig.API.reducerPath]: CommunityConfig.API.reducer,
    [CommonCodeFilterApi.reducerPath]: CommonCodeFilterApi.reducer,
    [BannerApi.reducerPath]: BannerApi.reducer,
    [SolutionApi.reducerPath]: SolutionApi.reducer,
    [SponsorApi.reducerPath]: SponsorApi.reducer
};

export default Reducer;
