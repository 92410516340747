import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const SponsorApi = createApi({
    reducerPath: "SponsorApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Sponsor"],
    endpoints: (build) => ({
        sponsorCreate: build.mutation({
            query: (body) => ({
                url: `${URL.SPONSOR_CREATE}`,
                method: "POST",
                body: body
            }),
        }),
    })
});

export const { useSponsorCreateMutation } = SponsorApi;
