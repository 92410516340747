import React from "react";

function Management() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn management sub_container">
                <h2 class="sub_custom_ttl">ESG경영</h2>
                <h3>WHAT WE DO</h3>
                <h6>희망나무재단이 지향하는 가치</h6>
                <p>기업이 나아가야 할 가치있는선택, 희망나무재단이 시작하겠습니다. </p>
                <div class="management_conts">
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/QNYyz0d7/man01.png" alt="ESG경영" />
                        </div>
                        <article>
                            <h4>기부 및 후원</h4>
                            <p>
                                기부와 후원, 봉사를 통해 <br /> 지속가능한 행복을 더 많은 <br />
                                사람들과 공유합니다.
                            </p>
                        </article>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/J49dr0J4/man02.png" alt="ESG경영" />
                        </div>
                        <article>
                            <h4>문화교류</h4>
                            <p>
                                문화 나눔을 통해 삶의 질을
                                <br /> 풍요롭게 만들어 긍정적인 <br />
                                변화를 만들어갑니다.
                            </p>
                        </article>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/dVrNLFmR/man03.png" alt="ESG경영" />
                        </div>
                        <article>
                            <h4>1인가구 지원</h4>
                            <p>
                                1인 가구의 문화 활동을 지원하여 <br />
                                사회적 유대감 형성과 네트워킹의
                                <br />
                                기회를 제공합니다.
                            </p>
                        </article>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/zfkdFkQV/man04.png" alt="ESG경영" />
                        </div>
                        <article>
                            <h4>윤리경영 아카데미</h4>
                            <p>
                                축적된 전문성과 노하우를 <br />
                                바탕으로 윤리경영 전반에 <br />
                                대한 강연을 진행합니다.
                            </p>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Management;
