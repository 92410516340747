import React from "react";

function Address() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn address sub_container">
                <h2 class="sub_custom_ttl">오시는 길</h2>
                <div class="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.1511346291!2d127.0446951763788!3d37.5043534276905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca40f628c8ee1%3A0x271a38c63e3dc7b2!2z67mF7Yag66as7JWE7Jik7ZS87Iqk7YWU!5e0!3m2!1sen!2skr!4v1712555411757!5m2!1sen!2skr"
                        width="100%"
                        height="100%"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        style={{ border: "0" }}
                    ></iframe>
                </div>
                <div class="contact">
                    <div class="item">
                        <img src="https://i.postimg.cc/kMLjRR0S/location.png" alt="오시는 길" />
                        <p>
                            <span>주소: </span> 서울 강남구 테헤란로 327 역삼동, 빅토리아오피스텔 5F
                        </p>
                    </div>
                    <div class="item">
                        <img src="https://i.postimg.cc/MGZtJ7QC/cotnact.png" alt="오시는 길" />
                        <p>
                            <span>연락처: </span> 1566 - 6960
                        </p>
                    </div>
                    <div class="item">
                        <img src="https://i.postimg.cc/kXxyV1Fr/metro.png" alt="오시는 길" />
                        <p>
                            <span>지하철: </span> 2호선 선릉역 5번 출구
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Address;
