import React from "react";

function Ethical() {
    return (
        <div class="sub_conts">
            <div class="sub_conts_inn ethical container">
                <section class="ethical_01">
                    <div class="img">
                        <img src="https://i.postimg.cc/65jkCJvv/eth-01.png" alt="윤리경영특강" />
                    </div>
                    <article>
                        <h3>윤리경영특강</h3>
                        <p>
                            조직 내 임직원의 윤리적 역량을 향상시키기 위해
                            <br /> 윤리적 민감성, 행동 기준 관리 방법을
                            <br /> 다양한 강연 프로그램을 통해 알려드리고 있습니다.
                        </p>
                    </article>
                </section>
                <section class="ethical_02">
                    <h3>
                        명강사 차희연의 기업윤리경영 <span>강연 후기</span>
                    </h3>
                    <a href="/sponsor">바로가기</a>
                    <div class="icon icon_left">
                        <img src="https://i.postimg.cc/ZR15jBg1/eth-02.png" alt="기업윤리경영" />
                    </div>
                    <div class="icon icon_right">
                        <img src="https://i.postimg.cc/kMb4GsR9/eth-03.png" alt="기업윤리경영" />
                    </div>
                </section>
                <section class="ethical_03">
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/5jcWQDyz/eth-04.png" alt="윤리경영특강" />
                        </div>
                        <div class="txt">
                            <h3>국토교통부</h3>
                            <p>심리학으로 배우는 청탁금지법교육</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/59XHvvj9/eth-05.png" alt="윤리경영특강" />
                        </div>
                        <div class="txt">
                            <h3>보건관리자교육</h3>
                            <p>직장 내 괴롭힘 사례 및 대처방안</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/ZYXWLRy8/eth-06.png" alt="윤리경영특강" />
                        </div>
                        <div class="txt">
                            <h3>공직자 청렴교육</h3>
                            <p>기업윤리경영을 심리학으로 배우기</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img src="https://i.postimg.cc/NjcLWzDL/eth-07.png" alt="윤리경영특강" />
                        </div>
                        <div class="txt">
                            <h3>기업윤리경영</h3>
                            <p>공무원 행동강령특강</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Ethical;
